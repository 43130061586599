import { OntologyType } from "@recare/core/model/utils/ontologies";
import { useGetOntologies } from "@recare/core/model/utils/ontologies/hooks";
import ConnectedSelectInput, {
  ConnectedSelectInputProps,
  SelectInput,
  SelectInputProps,
} from "ds/components/SelectInput";

type OntologyOption = { id: number; label: string; value: number };

type OntologyProps = {
  exclude?: Array<number>;
  include?: Array<number>;
  sort?: boolean;
  type: OntologyType;
};

export function useOntologyOptions({
  exclude,
  include,
  sort,
  type,
}: OntologyProps): OntologyOption[] {
  const getOntologies = useGetOntologies();
  let values = getOntologies({ type, sort });

  if (exclude != null && Array.isArray(exclude)) {
    values = values.filter((v) => !exclude.includes(v.value));
  }

  if (include != null && Array.isArray(include)) {
    values = values.filter((v) => include.includes(v.value));
  }

  return values.map(({ name, value }) => ({ label: name, value, id: value }));
}

export function OntologySelectInput<
  Multiple extends boolean | undefined = undefined,
  Nullable extends boolean | undefined = undefined,
>({
  exclude,
  include,
  sort,
  type,
  ...props
}: Omit<SelectInputProps<OntologyOption, Multiple, Nullable>, "options"> &
  OntologyProps) {
  const options = useOntologyOptions({
    exclude,
    include,
    sort,
    type,
  });

  return (
    <SelectInput<OntologyOption, Multiple, Nullable>
      {...props}
      options={options}
    />
  );
}

export default function ConnectedOntologySelectInput<
  Multiple extends boolean | undefined = undefined,
  Clearable extends boolean | undefined = undefined,
>({
  exclude,
  include,
  sort,
  type,
  ...props
}: Omit<
  ConnectedSelectInputProps<OntologyOption, Multiple, Clearable>,
  "options"
> &
  OntologyProps) {
  const options = useOntologyOptions({
    exclude,
    include,
    sort,
    type,
  });

  return (
    <ConnectedSelectInput<OntologyOption, Multiple, Clearable>
      {...props}
      options={options}
    />
  );
}

import { AnyObject } from "@recare/core/types";
import cloneDeep from "lodash/cloneDeep";

export function trimDecryptedR(data: any, parent?: any) {
  if (!data) return;
  if (typeof data !== "object") return;

  if ("decrypted" in data) {
    if ("content" in data) {
      delete data.decrypted;
    } else {
      const [encryptedFieldParent, encryptedField] = parent;
      delete encryptedFieldParent[encryptedField];
    }
    return;
  }

  if (Array.isArray(data)) {
    data.forEach((nextData) => {
      trimDecryptedR(nextData);
    });
    return;
  }

  Object.keys(data).forEach((key) => {
    const nextData = data[key];
    trimDecryptedR(nextData, [data, key]);
  });
}

/**
 *
 * @returns a deep copy of the data without decrypted properties
 */
export default function trimDecrypted<T>(
  data: T,
  trackProps?: {
    context: AnyObject;
    trackingData: AnyObject;
  },
): T {
  const dataCopy = cloneDeep(data);
  try {
    trimDecryptedR(dataCopy);
  } catch (error) {
    console.error(
      "trimDecrypted failed",
      error,
      JSON.stringify(trackProps?.context ?? {}),
      JSON.stringify(trackProps?.trackingData ?? {}),
    );
  }
  return dataCopy;
}

import { AnyObject } from "@recare/core/types";
import { fromJS, Iterable } from "immutable";
import { ValidationFunction } from "./types";

export function composeValidation<V = any, P = AnyObject>(
  ...validates: ValidationFunction<V, P>[]
) {
  return function validateValue(
    value: V,
    props: P = {} as P,
    globalValue?: Iterable<any, any> | null,
  ): AnyObject | boolean {
    return validates.reduce<AnyObject | boolean>(
      (reducer: any, validate: ValidationFunction<V, P>) => {
        const validated = validate(value, props, globalValue);
        if (validated === true) return reducer;
        if (reducer === true) return validated;
        return Object.assign({}, validated, reducer);
      },
      true,
    );
  };
}

export function notNull({ errorString = "Not filled" } = {}) {
  return function validateNotNull(value: any) {
    if (value === null) {
      return errorString;
    }
    return true;
  };
}

export function notUndefined({ errorString = "Not filled" } = {}) {
  return function validateNotUndefined(value: any) {
    if (value === undefined) {
      return errorString;
    }
    return true;
  };
}

export function notEmpty({ errorString = "Not filled" } = {}) {
  return function validateNotEmpty(value: any) {
    const stringValue = typeof value === "string";
    const trimmedValue = stringValue ? value.trim() : value;
    if (trimmedValue === "") {
      return errorString;
    }
    return true;
  };
}

export function required({ errorString = "Not filled" } = {}) {
  return composeValidation(
    notUndefined({ errorString }),
    notNull({ errorString }),
    notEmpty({ errorString }),
  );
}

export function lessThan(
  accessor1: any,
  accessor2: any,
  { error }: any = {
    error: "Invalid",
  },
) {
  return function validateLessThan(value: any, props?: any) {
    const value1 = fromJS(value).getIn(accessor1.split("."));
    const value2 = fromJS(value).getIn(accessor2.split("."));

    if (typeof value1 === "number" && typeof value2 === "number") {
      if (value1 > value2) {
        if (typeof error === "function") return error(value, props);
        return error;
      }
      return true;
    } else if (typeof value1 === "string" && typeof value2 === "string") {
      if (value1 > value2) {
        if (typeof error === "function") return error(value, props);
        return error;
      }
      return true;
    }
    return true;
  };
}

import { CUSTOM_BLACK, ICON_DARK, WHITE } from "ds/materials/colors";
import { border, dp } from "ds/materials/metrics";
import styled, { CSSProperties } from "styled-components";

const DOT_WIDTH = 6;

export const DotWithBorder = styled("span").attrs({
  "aria-hidden": true,
  role: "presentation",
})<{
  bold?: boolean;
  dark?: boolean;
  display?: CSSProperties["display"];
  margin?: CSSProperties["margin"];
  padding?: CSSProperties["padding"];
}>`
  background: ${(props) => props.color || WHITE};
  border-radius: 50%;
  border: ${(props) => border({ color: props.color || ICON_DARK })};
  height: ${dp(DOT_WIDTH)};
  width: ${dp(DOT_WIDTH)};
`;

export const Dot = styled("span").attrs({
  "aria-hidden": true,
  role: "presentation",
})<{
  bold?: boolean;
  color?: string;
  display?: CSSProperties["display"];
  fontSize?: CSSProperties["fontSize"];
  margin?: CSSProperties["margin"];
  padding?: CSSProperties["padding"];
}>`
  background-color: ${(props) => props.color || CUSTOM_BLACK};
  border-radius: 50%;
  display: ${(props) => props.display};
  margin: ${(props) => props.margin};
  padding: ${({ bold }) => (bold ? dp(2) : dp(1))};
  padding: ${({ padding }) => padding};
  font-size: ${({ fontSize }) => fontSize};
`;

import { visuallyHidden } from "@mui/utils";
import {
  APP_PROVIDER_SEARCH,
  LOGO_MASTER,
  TRACK_EVENTS,
} from "@recare/core/consts";
import { useInterval } from "@recare/core/hooks";
import { isStaging } from "@recare/core/model/utils/featureFlags";
import { useTranslations } from "@recare/translations";
import { useEnvContext } from "context/EnvContext";
import { useProviderSearchContext } from "context/ProviderSearchContext";
import { dp } from "ds/materials/metrics";
import { Body, Caption } from "ds/materials/typography";
import Image from "dsl/atoms/Image";
import { RENDER_DELAY, RenderDelay } from "dsl/atoms/LoadingDelay";
import React, { useLayoutEffect, useMemo, useRef, useState } from "react";
import { useTracking } from "react-tracking";
import styled from "styled-components";
import { RecareLogoOnly, RecareLogoOnlyProps } from "../RecareLogo";
import "./style.css";

const SPINNER_SIZES = {
  big: { height: dp(120), width: dp(120) },
  default: { height: dp(80), width: dp(80) },
  small: { height: dp(20), width: dp(20) },
};

const SpinnerContainer = styled.div<{ size: SpinnerSize }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  height: 100%;
  min-heigth: ${(props) => SPINNER_SIZES[props.size]};
`;

const SpinnerPageContainerStyled = styled.div<{ height?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: ${({ height }) => height || "100px"};
`;

function SpinnerInfo({ id }: { id: string }) {
  if (!isStaging) return null;
  return <Caption style={{ color: "#efefef" }}>{id}</Caption>;
}

type SpinnerSize = "big" | "default" | "small";

type SpinnerPropsType = {
  delay?: number;
  height?: string;
  id: string;
  size?: SpinnerSize;
  variant?: RecareLogoOnlyProps["variant"];
};

function Spinner({ id, size, variant }: SpinnerPropsType) {
  const [longSpinners, setLongSpinners] = useState(0);
  const { app } = useEnvContext();
  const { config } = useProviderSearchContext();
  const { trackEvent } = useTracking();
  const translations = useTranslations();

  const interval = 10 * 1000;

  const { height, width } = useMemo(() => {
    if (size) return SPINNER_SIZES[size];
    return SPINNER_SIZES["default"];
  }, [size]);

  const inStorybook = (window as any)?.IN_STORYBOOK;

  useInterval(() => {
    trackEvent({
      name: TRACK_EVENTS.SPINNER,
      spinner_id: id,
      times: longSpinners + 1,
      duration: (longSpinners + 1) * interval,
    });

    setLongSpinners((prev) => prev + 1);
  }, interval);

  return (
    <SpinnerContainer className="spinner-component" size={size ?? "default"}>
      <div className={inStorybook ? "" : "heartbeat-animation"}>
        {app === APP_PROVIDER_SEARCH ? (
          <Image alt="" path={config.logos.loading} style={{ width, height }} />
        ) : (
          <RecareLogoOnly
            variant={variant ?? LOGO_MASTER}
            style={{ width, height }}
          />
        )}
      </div>
      <Body style={visuallyHidden} whiteSpace="nowrap">
        {translations.general.status.loading}
      </Body>
      <SpinnerInfo id={id} />
    </SpinnerContainer>
  );
}

export const SpinnerPageContainer = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [containerOffsetTop, setContainerOffsetTop] = useState(0);

  useLayoutEffect(() => {
    const { current } = ref;
    const offsetTop = (current?.offsetParent as HTMLElement)?.offsetTop;
    setContainerOffsetTop(offsetTop || 0);
  }, [ref]);

  return (
    <SpinnerPageContainerStyled
      height={`${window.innerHeight - containerOffsetTop}px`}
      ref={ref}
    >
      {children}
    </SpinnerPageContainerStyled>
  );
};

export function SpinnerPage({
  id,
  size,
  delay = RENDER_DELAY,
  variant,
}: SpinnerPropsType) {
  return (
    <RenderDelay delay={delay}>
      <Spinner id={id} size={size} variant={variant} />
    </RenderDelay>
  );
}

export default SpinnerPage;

import {
  AUCTION_DRAFT_MOVED_TO_STOPPED_COSTPAYER_FLOW,
  AUCTION_PAUSE_MOVED_TO_STOPPED_SUNSET_COSTPAYER_FLOW,
  AUCTION_STOPPED_DISCHARGED_WITHOUT_PROVIDER,
  AUCTION_STOPPED_PATIENT_DIED,
  AUCTION_STOPPED_PATIENT_TRANSFERED,
  AUCTION_STOPPED_PAUSE_OTHER,
  AUCTION_STOPPED_SUNSET_COSTPAYER_FLOW,
  AUCTION_STOP_AUTOMATICALLY_ARCHIVED,
  AUCTION_STOP_COSTCOVERAGE_NOT_POSSIBLE,
  AUCTION_STOP_DISCHARGE_DELAYED,
  DEPRECATED_AUCTION_STOPPED_PROVIDER_NOT_NEEDED,
  DEPRECATED_AUCTION_STOP_PROVIDER_NOT_NEEDED_OTHER,
} from "@recare/core/consts";
import { Event } from "@recare/core/types";
import Translations from "@recare/translations/types";

export const getAuctionStopReasonTranslation = ({
  notNeededTranslation,
  stopReason,
  translations,
}: {
  notNeededTranslation: string;
  stopReason?: number;
  translations: Translations;
}) => {
  switch (stopReason) {
    case AUCTION_STOP_DISCHARGE_DELAYED:
      return translations.patient.stopAuction.dischargeDelayed;
    case AUCTION_STOPPED_PATIENT_DIED:
      return translations.patient.stopAuction.patientDied;
    case DEPRECATED_AUCTION_STOP_PROVIDER_NOT_NEEDED_OTHER:
    case DEPRECATED_AUCTION_STOPPED_PROVIDER_NOT_NEEDED:
      return notNeededTranslation;
    case AUCTION_STOPPED_PAUSE_OTHER:
      return translations.patient.stopAuction.otherReason;
    case AUCTION_STOP_COSTCOVERAGE_NOT_POSSIBLE:
      return translations.search.stopNotEligible;
    //old stop reason
    case AUCTION_STOP_AUTOMATICALLY_ARCHIVED:
      return translations.patient.stopAuction.automaticallyStopped;
    case AUCTION_STOPPED_PATIENT_TRANSFERED:
      return translations.patient.stopAuction.transferred;
    case AUCTION_STOPPED_DISCHARGED_WITHOUT_PROVIDER:
      return translations.patient.stopAuction.dischargedWithoutProvider.care;

    case AUCTION_STOPPED_SUNSET_COSTPAYER_FLOW:
      return translations.search.stoppedSunsetCostpayerflow;
    case AUCTION_DRAFT_MOVED_TO_STOPPED_COSTPAYER_FLOW:
      return translations.search.draftMovedToStoppedSunsetCostpayerflow;
    case AUCTION_PAUSE_MOVED_TO_STOPPED_SUNSET_COSTPAYER_FLOW:
      return translations.search.pausedMovedToStoppedSunsetCostpayerflow;

    default:
      return "";
  }
};

export const getAuctionStopReason = (
  event: Event,
  translations: Translations,
  modularAccountName: string,
  notNeededTranslation: string,
) => {
  const stopReason = event.context?.stop_reason;
  if (!stopReason) return;
  const accountName = modularAccountName || translations.timeline.you;
  const prefix = `${translations.timeline.auctionCancelled({
    accountName,
  })}${translations.general.colon}`;
  const reason = getAuctionStopReasonTranslation({
    translations,
    stopReason,
    notNeededTranslation,
  });
  const description = event.context?.stop_description
    ? `- ${event.context?.stop_description}`
    : "";
  return `${prefix} ${reason} ${description}`;
};

import { WarningOutlined } from "@mui/icons-material";
import { Checkbox, FormControlLabel } from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { visuallyHidden } from "@mui/utils";
import { useCountry } from "@recare/app/src/reduxentities/selectors/hooks";
import {
  ASSESSMENT_VARIANTS,
  SEARCH_TYPE_CARE,
  SEARCH_TYPE_HOME_CARE,
  SEARCH_TYPE_HOSPITAL,
  SEARCH_TYPE_MEDICAL_SUPPLIES,
  SEARCH_TYPE_REHABILITATION,
  SEARCH_TYPE_TRANSPORT,
  SEARCH_TYPES,
  SOLUTION_MOBILE_CARE,
  SOLUTION_SHORT_TERM_STATIC_CARE,
  TRACK_EVENTS,
  typeOfCareToRights,
} from "@recare/core/consts";
import {
  isAuctionRunning,
  isCare,
  isMultiSolutionSearch,
  isRehab,
} from "@recare/core/model/auctions";
import {
  checkAllowedDuplicateSearchType,
  getSolutionsForPatientType,
  getSpecializations,
  showMultipleSpecializations,
  showSpecializations,
} from "@recare/core/model/patients";
import { isProd } from "@recare/core/model/utils/featureFlags";
import { useGetOntology } from "@recare/core/model/utils/ontologies/hooks";
import { getValues } from "@recare/core/model/utils/ontologies/utils";
import { getQueryVariable } from "@recare/core/model/utils/urls";
import {
  Account,
  Auction,
  Careseeker,
  GetOntologyType,
  Patient,
  QueryProgress,
  SearchType,
  TrackEventFn,
} from "@recare/core/types";
import {
  composeValidation,
  convertIn,
  convertOut,
  FormWatcher,
  Show,
  validateModel,
  withForm,
  WithFormProps,
} from "@recare/react-forms-state";
import { useTranslations } from "@recare/translations";
import Translations from "@recare/translations/types";
import { getUnixTime } from "date-fns";
import CheckboxInputField from "ds/components/CheckboxInputField";
import DatePickerInputField from "ds/components/DatePickerInputField";
import { InfoBanner } from "ds/components/InfoBanner";
import InfoCaption from "ds/components/InfoCaption";
import ConnectedRadioChipGroup from "ds/components/RadioChipGroup";
import RadioGroup from "ds/components/RadioGroup";
import RSButton from "ds/components/RSButton";
import { SelectConnectedWithForm } from "ds/components/Select";
import ConnectedOntologySelectInput from "ds/components/SelectInput/Ontology";
import TextInputField from "ds/components/TextInputField";
import { HorizontalLayout, VerticalLayout } from "ds/materials/layouts";
import { dp, margin, padding } from "ds/materials/metrics";
import {
  Caption,
  FONT_SIZE_16,
  FONT_WEIGHT_BOLD,
  Subheading,
} from "ds/materials/typography";
import OntologyCheckboxGroup from "dsl/atoms/OntologyCheckboxGroup";
import SelectForOntology from "dsl/atoms/SelectForOntology";
import { useOnePageAssessment } from "dsl/ecosystems/PatientAssessment/useOnePageAssessment";
import { useCareseekerNavigationHandlers } from "dsl/hooks/useNavigationHandlers";
import { useCareseekerActions } from "dsl/molecules/ActionContexts/CareseekerActions";
import ExternalIdKisField from "dsl/molecules/ExternalIdKisField";
import OntologyRadioGroup from "dsl/molecules/OntologyRadioGroup";
import { useNotification } from "dsl/organisms/NotificationProvider";
import { ErrorNotification } from "dsl/organisms/Notifications";
import { useLocation } from "react-router-dom";
import { useTracking } from "react-tracking";
import styled from "styled-components";
import modelDefinition, {
  defaultSolutionsForSearchType,
} from "./modelDefinition";

const HospitalStayContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: ${margin(0, 0, 2, 0)};
`;

const TypeOfCareContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const ActionsWrapper = styled(HorizontalLayout)`
  margin: ${margin(5, 0, 1, 0)};
  justify-content: center;
`;

const TEXT_LABEL_WIDTH = 332;
export const textLabelWidth = dp(TEXT_LABEL_WIDTH);

export const solutionSortOrder = {
  "8": 1,
  "1": 2,
  "2": 3,
  "4": 4,
  "5": 5,
  "3": 6,
};
const patientTypeSortOrder = {
  "1": 1,
  "2": 2,
};

const searchTypeDisabled = ({
  careseeker,
  patient,
  roles,
  searchType,
}: {
  careseeker: Careseeker;
  patient?: Patient;
  roles?: number[];
  searchType: SearchType;
}): { disable: boolean; hide: boolean } => {
  if (!roles && searchType === SEARCH_TYPE_CARE)
    return { hide: false, disable: false };

  const duplicateAllowed = checkAllowedDuplicateSearchType({
    searchType,
    auctions: patient?.auctions,
  });

  if (!duplicateAllowed) return { hide: false, disable: true };

  const disabledByRoles = !typeOfCareToRights[searchType]?.some(
    (right) => roles?.includes(right),
  );

  const disabledByCareseeker = !careseeker.patient_types?.includes(searchType);

  const disabledSearchType = disabledByRoles || disabledByCareseeker;

  if (!disabledByRoles && disabledByCareseeker) {
    console.warn(
      `user with role for search type: ${searchType} for careseeker ${careseeker.id} without available patient type in: ${careseeker.patient_types}`,
    );
  }
  if (
    (
      [
        SEARCH_TYPE_HOME_CARE,
        SEARCH_TYPE_MEDICAL_SUPPLIES,
        SEARCH_TYPE_TRANSPORT,
      ] as number[]
    ).includes(searchType)
  ) {
    return { hide: disabledByCareseeker, disable: disabledByRoles };
  }
  return { hide: false, disable: disabledSearchType };
};

export const getPatientTypeOptions = ({
  careseeker,
  getOntology,
  patient,
  roles,
  translations,
}: {
  careseeker: Careseeker | null;
  getOntology: AnyFunction;
  patient?: Patient;
  roles?: number[];
  translations: Translations;
}) => {
  if (!careseeker) return [];

  return SEARCH_TYPES.map((searchType) => {
    const { disable, hide } = searchTypeDisabled({
      careseeker,
      searchType,
      roles,
      patient,
    });
    if (hide) return null;
    return {
      name: getOntology({ type: "patientType", key: searchType }),
      value: searchType,
      tooltipText: disable ? translations.patient.upgradePlan : undefined,
      disabled: disable,
    };
  }).truthy();
};

export function SpecializationSelect({
  careseeker,
  required,
  searchType,
}: {
  careseeker: Careseeker;
  required?: boolean;
  searchType: SearchType;
}) {
  const translations = useTranslations();
  const country = useCountry();
  const specializations = getSpecializations({
    searchType,
    careseeker,
    country,
  });
  const showMultiple = showMultipleSpecializations({ searchType });

  return (
    <SelectForOntology
      label={
        showMultiple ? translations.ontologies.specializations.key : undefined
      }
      required={required}
      connected
      elementName="specializations"
      type="specializations"
      include={specializations}
      floatingLabel
      withPortal
      placeholder={translations.general.selectDefaultPlaceholderShort}
      multiple={showMultiple}
    />
  );
}

const flexibleDateBlocklist: SearchType[] = [
  SEARCH_TYPE_TRANSPORT,
  SEARCH_TYPE_REHABILITATION,
  SEARCH_TYPE_MEDICAL_SUPPLIES,
  SEARCH_TYPE_HOME_CARE,
];

const showFlexibleStartDate = (searchType: SearchType) => {
  if (flexibleDateBlocklist.includes(searchType)) return false;
  return true;
};

const ShortAssessmentSelect = ({
  solutions,
  translations,
}: {
  solutions: Array<number>;
  translations: Translations;
}) => {
  const isMobileSolution =
    solutions?.length == 1 && solutions[0] === SOLUTION_MOBILE_CARE;
  return (
    <div
      style={{
        display: isMobileSolution ? "flex" : "none",
        flexDirection: "column",
        margin: margin(4, 0, 0, 0),
      }}
    >
      <Subheading bold>{translations.patient.complexityPatient}</Subheading>
      <HorizontalLayout padding={padding(0, 0, 0, 2)}>
        <RadioGroup
          elementName="assessment_variant"
          options={[
            {
              // @ts-ignore
              value: "mobile-short",
              label: translations.careseeker.shortMobileAssessment,
              id: 1,
            },
            {
              // @ts-ignore
              value: "",
              label: translations.careseeker.longMobileAsssessment,
              id: 2,
            },
          ]}
        />
      </HorizontalLayout>
    </div>
  );
};

const getSolutionOptions = (patientType: SearchType) =>
  getValues("solution")
    // @ts-ignore
    .map((id) => parseInt(id))
    .filter((sol) => getSolutionsForPatientType(patientType).includes(sol));

function SolutionCaption({ translations }: { translations: Translations }) {
  return (
    <InfoCaption
      margin={margin(0, 0, 1, 2)}
      text={translations.patient.transferChanges}
    />
  );
}

const SolutionsSelect = ({ searchType }: { searchType: SearchType }) => {
  if (isMultiSolutionSearch(searchType)) {
    return (
      <OntologyCheckboxGroup
        elementName="solutions"
        include={getSolutionOptions(searchType)}
        label=""
        labelStyle={{ margin: margin(0) }}
        order={solutionSortOrder}
        sideMutation={(value, mutateElement) => {
          mutateElement("", "assessment_variant");
        }}
        type="solution"
        wrapperStyle={{ margin: margin(0, 0, 0, 2) }}
      />
    );
  }
  return (
    <div style={{ margin: margin(2) }}>
      <OntologyRadioGroup
        elementName="solutions"
        type="solution"
        label=""
        includeOptions={getSolutionOptions(searchType)}
      />
    </div>
  );
};

const showStartDate = (
  searchType: SearchType,
  solutions: number[] | number,
) => {
  let show = solutions != null;
  if (isMultiSolutionSearch(searchType)) {
    show = show && Array.isArray(solutions) && solutions.length >= 1;
  } else {
    show = show && (solutions as number) > 0;
  }
  return show;
};

export function PatientCreateFormBase({
  auction,
  careseeker,
  isCreating,
  roles,
  translations,
}: {
  auction?: Auction;
  careseeker: Careseeker;
  isCreating: boolean;
  roles?: Array<number>;
  translations: Translations;
}) {
  const country = useCountry();
  const getOntology = useGetOntology();
  const location = useLocation();
  const externalIdQueryVar =
    getQueryVariable(location.search, "external_id") ||
    auction?.patient?.external_id;

  const searchStarted = auction && isAuctionRunning(auction.status);

  const stationOptions = careseeker.stations_full
    ? careseeker.stations_full.map(
        ({ id = -1, name = "", description = "" }) => ({
          label: `${name}${description ? ` - ${description}` : ""}`,
          id,
          value: id,
        }),
      )
    : [];

  const patientOptions = getPatientTypeOptions({
    translations,
    roles,
    getOntology,
    careseeker,
  });
  return (
    <>
      {isCreating && (
        <DialogTitle style={{ padding: padding(2) }}>
          {translations.patient.createPatient}
        </DialogTitle>
      )}
      <DialogContent
        style={{ padding: padding(0, 0, 3, 0), overflow: "visible" }}
      >
        <Subheading bold>{translations.patient.hospitalStay}</Subheading>
        <ExternalIdKisField
          careseeker={careseeker}
          textLabelWidth={textLabelWidth}
          externalIdQueryVar={externalIdQueryVar}
        />
        <HospitalStayContainer>
          <VerticalLayout
            data-testid="station"
            width={textLabelWidth}
            margin={margin(1, 2, 0, 2)}
            overflow="visible"
          >
            {careseeker.stations_full && (
              <SelectConnectedWithForm
                elementName="station_full_id"
                withPortal
                sideMutation={(
                  value: { id: number } | undefined,
                  mutateElement: (v: any, name: string) => void,
                ) => {
                  if (value) {
                    const station = careseeker.stations_full?.find(
                      (s) => s.id == value.id,
                    );

                    console.log("MUTATING", station, value);
                    if (station?.phone_number)
                      mutateElement(
                        station.phone_number,
                        "doctor_in_charge_in_hospital_phone",
                      );
                  }
                }}
                width={textLabelWidth}
                options={stationOptions}
                value={stationOptions.length === 1 && stationOptions}
                label={translations.patient.station}
                placeholder={translations.general.selectDefaultPlaceholderShort}
                fontSize={FONT_SIZE_16}
              />
            )}
          </VerticalLayout>
          <TextInputField
            elementName="doctor_in_charge_in_hospital_phone"
            width={textLabelWidth}
            label={translations.patient.stationPhoneNumber}
            hasCustomValidation
          />
        </HospitalStayContainer>
        <VerticalLayout maxWidth={dp(475)}>
          <Subheading bold>{`${translations.patient.transfer} *`}</Subheading>
        </VerticalLayout>
        {searchStarted && <SolutionCaption translations={translations} />}
        <TypeOfCareContainer>
          <div style={{ maxWidth: dp(400) }}>
            <ConnectedRadioChipGroup
              elementName="search_type"
              sideMutation={(
                newValue: SearchType,
                mutateElement: (s: any, k: string) => void,
              ) => {
                mutateElement(
                  defaultSolutionsForSearchType(newValue),
                  "solutions",
                );

                const today = getUnixTime(new Date());
                const patientTypeSpecializations = getSpecializations({
                  searchType: newValue,
                  country,
                  careseeker,
                });

                if (newValue === SEARCH_TYPE_HOSPITAL) {
                  mutateElement(today, "start_date");
                }

                if (patientTypeSpecializations.length == 1) {
                  mutateElement(
                    {
                      label: getOntology({
                        type: "specializations",
                        key: patientTypeSpecializations[0],
                      }),
                      value: patientTypeSpecializations[0],
                      id: patientTypeSpecializations[0],
                    },
                    "specializations",
                  );
                } else {
                  mutateElement(null, "specializations");
                }
              }}
              options={patientOptions}
              sortOrder={patientTypeSortOrder}
              disabled={!isCreating}
              radioGroupSx={{ margin: margin(1, 0, 2, 2) }}
              label={translations.patient.patientType}
              id="search_type"
              formLabelSx={{
                padding: padding(0, 0, 0, 2),
                fontWeight: FONT_WEIGHT_BOLD,
                ...visuallyHidden,
              }}
            />
          </div>

          <FormWatcher watchPath="search_type">
            {({ watchedValue: searchType }) => {
              return (
                <div style={{ minHeight: "119px", width: "100%" }}>
                  <div
                    hidden={[
                      SEARCH_TYPE_MEDICAL_SUPPLIES,
                      SEARCH_TYPE_HOME_CARE,
                    ].includes(searchType)}
                  >
                    <SolutionsSelect searchType={searchType} />
                  </div>

                  <FormWatcher watchPath="solutions">
                    {({ watchedValue: solutions }) => {
                      const showAll = showStartDate(searchType, solutions);
                      const showSpecialization = showSpecializations({
                        searchType,
                      });

                      return (
                        <div hidden={!showAll}>
                          <div hidden={!showSpecialization}>
                            <VerticalLayout
                              margin={margin(1, 2)}
                              overflow="visible"
                              width={textLabelWidth}
                            >
                              <SpecializationSelect
                                required
                                careseeker={careseeker}
                                searchType={searchType}
                              />
                            </VerticalLayout>
                          </div>
                          <div style={{ padding: padding(1, 2) }}>
                            <DatePickerInputField
                              disablePast
                              elementName="start_date"
                              inputSx={{
                                width: textLabelWidth,
                                margin: margin(0),
                              }}
                              key="start_date"
                              label={translations.patient.expectedStartDate}
                              required
                            />
                          </div>
                          {isRehab(searchType) && (
                            <div style={{ padding: padding(1, 2) }}>
                              <DatePickerInputField
                                elementName="operation_date"
                                inputSx={{
                                  width: textLabelWidth,
                                  margin: margin(0),
                                }}
                                key="operation_date"
                                label={
                                  translations.patient.medicalDiagnosis
                                    .operationDate
                                }
                              />
                            </div>
                          )}
                          {showFlexibleStartDate(searchType) && (
                            <Show elementName="start_date_flexible">
                              <CheckboxInputField
                                elementName="start_date_flexible"
                                label={translations.patient.flexibleDate}
                                margin={margin(-0.5, 0, 0, 0.5)}
                              />
                            </Show>
                          )}
                          {isCare(searchType) && (
                            <VerticalLayout
                              width={textLabelWidth}
                              margin={margin(2)}
                            >
                              <HorizontalLayout width={dp(332)}>
                                <ConnectedOntologySelectInput
                                  elementName="care_duration_in_days"
                                  label={translations.patient.careDuration}
                                  required
                                  type="careDurations"
                                />
                              </HorizontalLayout>
                              <CheckboxInputField
                                elementName="interested_long_term_stay"
                                label={
                                  translations.patient.interestedStayLongTerm
                                }
                                hide={
                                  !solutions?.includes(
                                    SOLUTION_SHORT_TERM_STATIC_CARE,
                                  )
                                }
                                margin={margin(1, 0, 0, -1.5)}
                              />
                            </VerticalLayout>
                          )}
                          <ShortAssessmentSelect
                            solutions={solutions}
                            translations={translations}
                          />
                          <div hidden={!isRehab(searchType)}>
                            <HorizontalLayout padding={padding(0, 0, 0, 2)}>
                              <CheckboxInputField
                                elementName="direct_transfer_necessary"
                                label={
                                  translations.patient.directTransferNecessary
                                }
                              />
                            </HorizontalLayout>
                            <div>
                              <HorizontalLayout
                                padding={padding(0, 0, 0, 2)}
                                aligned
                              >
                                <CheckboxInputField
                                  elementName="assessment_variant"
                                  label={
                                    translations.patient
                                      .electivePatientNotInHospitalYet
                                  }
                                  customValue={
                                    ASSESSMENT_VARIANTS.ELECTIVE_REHAB
                                  }
                                />
                              </HorizontalLayout>
                              <InfoBanner
                                noIcon
                                severity="info"
                                message={
                                  translations.careproviderApp.settings.payers
                                    .infofieldRehabShortInsurances
                                }
                              />
                            </div>
                          </div>
                        </div>
                      );
                    }}
                  </FormWatcher>
                </div>
              );
            }}
          </FormWatcher>
        </TypeOfCareContainer>
      </DialogContent>
      <HorizontalLayout margin={margin(3, 2, 0, 2)}>
        <CheckboxInputField
          elementName="has_patient_consent"
          label={`${translations.patient.declarationOfConsent} *`}
        />
      </HorizontalLayout>
    </>
  );
}

function ActivateOnePageAssessmentToggle() {
  const translations = useTranslations();
  const { isOnePageAssessment, toggleOnePageAssessment } =
    useOnePageAssessment();

  return (
    <VerticalLayout margin={margin(0, 0, 0, 2)}>
      <HorizontalLayout aligned>
        <FormControlLabel
          control={
            <Checkbox
              checked={isOnePageAssessment}
              onChange={() => {
                toggleOnePageAssessment();
              }}
            />
          }
          label={
            translations.assessments.onePageAssessment.activateOnePageAssessment
          }
        />
      </HorizontalLayout>
      {isOnePageAssessment && (
        <div style={{ margin: margin(-1, 0, 1, 3.2) }}>
          <HorizontalLayout aligned>
            <Subheading>
              {translations.assessments.onePageAssessment.caution}
            </Subheading>
            <WarningOutlined />
          </HorizontalLayout>
          <Caption>
            {translations.assessments.onePageAssessment.disclaimer}
          </Caption>
        </div>
      )}
    </VerticalLayout>
  );
}

function PatientCreateFormContent({
  auction,
  careseeker,
  queryProgress,
  roles,
  submit,
}: {
  auction?: Auction;
  careseeker: Careseeker;
  queryProgress: QueryProgress;
  roles?: Array<number>;
  submit: (s?: any) => void;
}) {
  const { goToTodo } = useCareseekerNavigationHandlers();
  const translations = useTranslations();
  const careseekerActions = useCareseekerActions();
  const actions = [
    <RSButton
      inverted
      key="cancel"
      id="cancel"
      loading="na"
      onClick={() => goToTodo()}
      style={{ width: dp(150), margin: margin(1, 2.5, 1, 1) }}
      variant="contained"
    >
      {translations.actions.cancel}
    </RSButton>,
    <RSButton
      disabled={!careseekerActions.create_patients}
      className="createPatientButton"
      color="secondary"
      id="add_patient"
      key="create"
      loading={queryProgress}
      onClick={submit}
      style={{ width: dp(158), margin: margin(1, 1, 1, 2.5) }}
      variant="contained"
    >
      {translations.menu.addPatientButton}
    </RSButton>,
  ];

  return (
    <>
      <PatientCreateFormBase
        isCreating
        careseeker={careseeker}
        auction={auction}
        roles={roles}
        translations={translations}
      />
      {!isProd && <ActivateOnePageAssessmentToggle />}
      <ErrorNotification
        queryProgress={queryProgress}
        message={translations.patient.createPatientError}
      />
      <ActionsWrapper>{actions}</ActionsWrapper>
    </>
  );
}

export function validatePatientTransfer(value: any, props: any) {
  return composeValidation(validateModel(modelDefinition), (v: any, p: any) => {
    const shouldShow = showSpecializations({ searchType: v.search_type });

    const specializations = Array.isArray(v.specializations || [])
      ? v.specializations
      : v.specializations.value;

    if (
      !shouldShow ||
      (typeof specializations == "number" && specializations > 0) ||
      (Array.isArray(specializations) && specializations?.length > 0)
    ) {
      return true;
    }

    return {
      specializations: p.translations.general.required,
    };
  })(value, props);
}

function validatePatientCreationWithFeedback(value: any, props: any) {
  const validation = validatePatientTransfer(value, props);

  if (validation !== true) {
    props.showNotification({
      message: props.translations.actions.validationErrorMissingOrInvalid,
    });

    props.trackEvent({
      name: TRACK_EVENTS.ASSESSMENT_MISSING_FIELDS_ERROR_MESSAGE_DISPLAYED,
      fields_missing:
        typeof validation === "object" ? Object.keys(validation) : [],
    });
  }

  return validation;
}

const ConnectedPatientCreateForm = withForm<
  PatientCreateFormProps & WithFormProps & WithNotifications & WithTracking
>(PatientCreateFormContent);

export type PatientCreateFormProps = {
  account: Account;
  careseeker: Careseeker;
  className?: string;
  getOntology: GetOntologyType;
  onPatientNameChange?: (s: (o: AnyObject) => AnyObject) => void;
  onSubmit: (value: Auction) => void;
  queryProgress: QueryProgress;
  roles?: number[];
  translations: Translations;
};

type WithNotifications = {
  showNotification: ReturnType<typeof useNotification>;
};

type WithTracking = {
  trackEvent: TrackEventFn;
};

export default function PatientCreateForm(props: PatientCreateFormProps) {
  const showNotification = useNotification();
  const { trackEvent } = useTracking();

  return (
    <ConnectedPatientCreateForm
      modelDefinition={modelDefinition}
      convertIn={convertIn(modelDefinition)}
      convertOut={convertOut(modelDefinition)}
      validate={validatePatientCreationWithFeedback}
      showNotification={showNotification}
      trackEvent={trackEvent}
      {...props}
    />
  );
}

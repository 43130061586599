import Config, { ENV_DEVELOPMENT, ENV_STAGING } from "@recare/core/model/config";
import trimDecrypted from "@recare/core/model/encryption/trimDecrypted";
import { isCI } from "@recare/core/model/utils/featureFlags";
import { Auction, GetOntologyType } from "@recare/core/types";
import { padding } from "ds/materials/metrics";
import FaxCoverLetter from "dsl/molecules/fax/faxCoverLetter";
import PrintPatientInfo from "dsl/organisms/Infos/PatientInformation/PrintPatientInfo";
import ReactDOMServer from "react-dom/server";

// https://stackoverflow.com/questions/30106476/using-javascripts-atob-to-decode-base64-doesnt-properly-decode-utf-8-strings
function b64EncodeUnicode(str: string) {
  // first we use encodeURIComponent to get percent-encoded UTF-8,
  // then we convert the percent encodings into raw bytes which
  // can be fed into btoa.
  return btoa(
    encodeURIComponent(str).replace(
      /%([0-9A-F]{2})/g,
      function toSolidBytes(match, p1) {
        return String.fromCharCode(parseInt("0x" + p1, 16));
      },
    ),
  );
}

function b64DecodeUnicode(str: string) {
  // Going backwards: from bytestream, to percent-encoding, to original string.
  return decodeURIComponent(
    atob(str)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(""),
  );
}

export default function getPatientProfileHTML({
  auction,
  getOntology,
  type,
}: {
  auction: Auction;
  getOntology: GetOntologyType;
  type: number;
}) {
  const safeAuction = trimDecrypted(auction);
  const patientProfileHTML = ReactDOMServer.renderToStaticMarkup(
    <>
      <div>
        <FaxCoverLetter
          auction={safeAuction}
          faxType={type}
          getOntology={getOntology}
        />
      </div>
      <div style={{ padding: padding(0) }}>
        <PrintPatientInfo
          auction={safeAuction}
          faxPrint
          getOntology={getOntology}
        />
      </div>
    </>,
  );

  const content =
    `<!DOCTYPE html>` +
    `<html>` +
    `<head><meta charset="UTF-8" /></head>` +
    patientProfileHTML +
    `</html>`;

  const base64encodedContent = b64EncodeUnicode(content);

  if (
    (Config.environment === ENV_DEVELOPMENT ||
      Config.environment === ENV_STAGING) &&
    !isCI
  ) {
    const newWindow = window.open("about:blank", "", "_blank");
    if (!newWindow)
      console.log("could not open new window to show fax document");
    else newWindow.document.write(b64DecodeUnicode(base64encodedContent));
  }

  return base64encodedContent;
}

import { FormControl, Paper, SxProps, Theme } from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import {
  PREDICAMENT_STATE_NO,
  PREDICAMENT_STATE_YES,
} from "@recare/core/consts";
import {
  FormElementProps,
  FormWatcher,
  isValid,
} from "@recare/react-forms-state";
import { useTranslations } from "@recare/translations";
import Translations from "@recare/translations/types";
import DatePickerInputField, {
  ConnectedDateSelectorProps,
} from "ds/components/DatePickerInputField";
import { SubheadingFormLabel } from "ds/components/FormComponents/SubheadingFormLabel";
import RadioGroupV2, { RadioOptionV2 } from "ds/components/RadioGroupV2";
import ConnectedSelectInput, {
  ConnectedSelectInputProps,
} from "ds/components/SelectInput";
import { ConnectedTextarea, TextAreaProps } from "ds/components/TextArea";
import TextInputField, {
  ConnectedTextInputProps,
} from "ds/components/TextInputField";
import ToggleInputField from "ds/components/ToggleInputField";
import {
  CUSTOM_WHITE,
  RADIO_WHITE_BACKGROUND,
  WHITE,
} from "ds/materials/colors";
import { HorizontalLayout, VerticalLayout } from "ds/materials/layouts";
import { dp, margin, padding, sizing } from "ds/materials/metrics";
import { SHADOW_DROP_SMALL } from "ds/materials/shadows";
import {
  Caption,
  FONT_SIZE_14,
  FONT_SIZE_20,
  FONT_WEIGHT_BOLD,
  Subheading,
} from "ds/materials/typography";
import { CSSProperties, ReactNode } from "react";
import styled from "styled-components";

export const GAP = 3;

export const LARGE_INPUT_WIDTH = "35%";
export const LARGE_INPUT_MIN_WIDTH = dp(280);

export const SMALL_INPUT_WIDTH = `calc(17.5% - ${sizing(1.5)})`;
export const SMALL_INPUT_MIN_WIDTH = dp(130);

export const TEXT_AREA_MAX_CHARACTERS = 400;

export const StyledFormWrapper = styled(Paper)`
  box-shadow: ${SHADOW_DROP_SMALL} !important;
  border-radius: ${dp(6)};
  box-sizing: border-box;
  overflow: visible;
  width: 100%;
  max-width: ${dp(1366)};
  padding: ${padding(4, 15)};
  margin: ${sizing(5)} auto;
`;

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: ${sizing(1)};
  background-color: ${CUSTOM_WHITE};
  padding: ${padding(GAP)};
  box-sizing: border-box;
  overflow: visible;
`;

export const SectionRow = styled(HorizontalLayout)<{
  customPadding?: CSSProperties["padding"];
}>`
  column-gap: ${sizing(GAP)};
  padding: ${({ customPadding }) => customPadding ?? padding(GAP, 0, 0)};
  box-sizing: border-box;
  flex-wrap: wrap;
  overflow: visible;
`;

export const Section = ({
  children,
  title,
}: {
  children: ReactNode[] | ReactNode;
  title?: string;
}) => {
  return (
    <SectionWrapper>
      {title ? (
        <Subheading
          as="h4"
          margin={margin(0, 0, 0.5, 0.5)}
          style={{
            fontSize: FONT_SIZE_20,
            fontWeight: FONT_WEIGHT_BOLD,
          }}
        >
          {title}
        </Subheading>
      ) : null}
      {children}
    </SectionWrapper>
  );
};

export const FormSectionsWrapper = ({
  children,
  withBottomMargin,
}: {
  children: ReactNode[] | ReactNode;
  withBottomMargin?: boolean;
}) => {
  return (
    <VerticalLayout
      width="100%"
      margin={margin(1, 0, withBottomMargin ? 3 : 1)}
      rowGap={sizing(GAP)}
    >
      {children}
    </VerticalLayout>
  );
};

export const WithLabel = ({
  bold,
  children,
  error,
  label,
  required,
  wrapperSx,
}: {
  bold?: boolean;
  children: ReactNode;
  error: boolean;
  label: ReactNode | undefined;
  required?: boolean;
  wrapperSx: SxProps<Theme>;
}) => {
  return (
    <FormControl component="fieldset" error={error} sx={wrapperSx}>
      <SubheadingFormLabel
        sx={{
          margin: margin(0, 0, 1),
          fontSize: FONT_SIZE_14,
          fontWeight: bold ? FONT_WEIGHT_BOLD : undefined,
        }}
      >
        <label>
          {label}
          {required ? " *" : ""}
        </label>
      </SubheadingFormLabel>
      {children}
    </FormControl>
  );
};

const LabelWrapper = ({
  bold,
  children,
  elementName,
  label,
  required,
  wrapperSx,
}: {
  bold?: boolean;
  children: ReactNode;
  elementName: string;
  label: ReactNode | undefined;
  required?: boolean;
  wrapperSx: SxProps<Theme>;
}) => {
  return (
    <FormWatcher watchPath={elementName}>
      {({ watchedValidation }) => {
        return (
          <WithLabel
            bold={bold}
            label={label}
            error={!isValid(watchedValidation)}
            wrapperSx={wrapperSx}
            required={required}
          >
            {children}
          </WithLabel>
        );
      }}
    </FormWatcher>
  );
};

export const DatePickerWithLabel = (
  props: ConnectedDateSelectorProps & {
    bold?: boolean;
    elementName: string;
    wrapperSx?: SxProps;
  },
) => {
  const { bold, label, ...restProps } = props;
  return (
    <LabelWrapper
      bold={bold}
      wrapperSx={{
        width: SMALL_INPUT_WIDTH,
        minWidth: SMALL_INPUT_MIN_WIDTH,
        ...(restProps?.wrapperSx ?? {}),
      }}
      label={label}
      elementName={restProps.elementName}
      required={restProps.required}
    >
      <DatePickerInputField
        {...restProps}
        textInputVariant="outlined"
        inputSx={{
          background: WHITE,
          width: "100%",
          ...(restProps?.inputSx ?? {}),
        }}
      />
    </LabelWrapper>
  );
};

export const TextInputWithLabel = (
  props: ConnectedTextInputProps & {
    bold?: boolean;
    elementName: string;
    labelWrapperSx?: SxProps<Theme>;
    large?: boolean;
  },
) => {
  const { bold, label, labelWrapperSx, ...restProps } = props;
  return (
    <LabelWrapper
      bold={bold}
      wrapperSx={{
        width: props.large ? LARGE_INPUT_WIDTH : SMALL_INPUT_WIDTH,
        minWidth: props.large ? LARGE_INPUT_MIN_WIDTH : SMALL_INPUT_MIN_WIDTH,
        ...labelWrapperSx,
      }}
      label={label}
      elementName={restProps.elementName}
    >
      <TextInputField
        label={null}
        width="100%"
        marginOverride="0"
        variant="outlined"
        style={{
          background: WHITE,
        }}
        {...restProps}
      />
    </LabelWrapper>
  );
};

export const TextAreaWithLabel = (
  props: TextAreaProps &
    FormElementProps & {
      bold?: boolean;
      elementName: string;
    },
) => {
  const { bold, label, ...restProps } = props;
  return (
    <LabelWrapper
      bold={bold}
      elementName={restProps.elementName}
      label={label}
      wrapperSx={{
        width: "100%",
      }}
      required={props.required}
    >
      <ConnectedTextarea label={null} {...restProps} />
    </LabelWrapper>
  );
};

export const SelectWithLabel = (
  props: ConnectedSelectInputProps & {
    bold?: boolean;
    elementName: string;
    inputSx?: SxProps<Theme>;
    wrapperSx: SxProps<Theme>;
  },
) => {
  const { bold, inputSx, label, wrapperSx, ...restProps } = props;
  return (
    <LabelWrapper
      bold={bold}
      elementName={restProps.elementName}
      label={label}
      wrapperSx={wrapperSx}
    >
      <ConnectedSelectInput
        label={null}
        {...restProps}
        size="small"
        variant="outlined"
        inputSx={{
          width: SMALL_INPUT_WIDTH,
          minWidth: SMALL_INPUT_MIN_WIDTH,
          background: WHITE,
          ...inputSx,
        }}
      />
    </LabelWrapper>
  );
};

export const ActivableToggle = ({
  componentIfFalse,
  componentIfTrue,
  elementName,
  label,
  sideMutation,
  wrapperStyle,
}: {
  componentIfFalse?: React.ReactNode;
  componentIfTrue?: React.ReactNode;
  elementName: string;
  label: string;
  sideMutation?:
    | ((newValue: boolean, mutateElement: AnyFunction) => void)
    | undefined;
  wrapperStyle?: CSSProperties;
}) => {
  return (
    <FormWatcher watchPath={elementName}>
      {({ watchedValue }) => {
        return (
          <VerticalLayout gap={sizing(2)} overflow="visible">
            <ToggleInputField
              elementName={elementName}
              label={label}
              noIndent
              wrapperStyle={{
                width: "100%",
                padding: padding(0),
                overflow: "visible",
                ...wrapperStyle,
              }}
              sideMutation={sideMutation}
            />
            {watchedValue ? componentIfTrue : componentIfFalse}
          </VerticalLayout>
        );
      }}
    </FormWatcher>
  );
};

export type Footnote = {
  description: (translations: Translations) => string;
  label: (translations: Translations) => string;
  superText: 1 | 2;
};

export const getFootnoteAriaLabel = (
  footNote: Footnote,
  translations: Translations,
) => `${footNote.label(translations)} - ${footNote.description(translations)}`;

export const FootnoteDescription = ({ footnote }: { footnote: Footnote }) => {
  const translations = useTranslations();

  return (
    <Caption aria-hidden margin={margin(0, 0, 1)}>
      <sup style={{ paddingRight: dp(2) }}>{footnote.superText}</sup>
      {footnote.description(translations)}
    </Caption>
  );
};

export const FootnoteLabel = ({ footnote }: { footnote: Footnote }) => {
  const translations = useTranslations();

  return (
    <>
      <span style={visuallyHidden}>
        {getFootnoteAriaLabel(footnote, translations)}
      </span>
      <span tabIndex={-1} aria-hidden style={{ position: "relative" }}>
        {footnote.label(translations)}
        <sup
          tabIndex={-1}
          aria-hidden
          style={{ paddingLeft: dp(2), position: "absolute", top: -8 }}
        >
          {footnote.superText}
        </sup>
      </span>
    </>
  );
};

export const YesNoRadioWithLabel = ({
  elementName,
  formLabelSx,
  label,
  subForms = {},
  sideMutation,
}: {
  elementName: string;
  formLabelSx?: CSSProperties;
  label: string;
  sideMutation?:
    | ((
        newValue: any,
        mutateElement: (value: any, elementName: string) => void,
      ) => void)
    | undefined;
  subForms?: { [key: string]: React.ReactNode };
}) => {
  const translations = useTranslations();

  const defaultYesNoOptions: RadioOptionV2[] = [
    {
      id: PREDICAMENT_STATE_YES,
      value: PREDICAMENT_STATE_YES,
      label: translations.actions.yes,
      subForm: subForms[PREDICAMENT_STATE_YES],
    },
    {
      id: PREDICAMENT_STATE_NO,
      value: PREDICAMENT_STATE_NO,
      label: translations.actions.no,
      subForm: subForms[PREDICAMENT_STATE_NO],
    },
  ];

  return (
    <RadioGroupV2
      label={label}
      formControlLabelSx={{ paddingBottom: sizing(0.5), marginLeft: 0 }}
      formLabelSx={{
        padding: padding(1, 0),
        fontSize: FONT_SIZE_14,
        ...formLabelSx,
      }}
      elementName={elementName}
      options={defaultYesNoOptions}
      radioSx={RADIO_WHITE_BACKGROUND}
      sideMutation={sideMutation}
    />
  );
};

import { MAX_REQUESTS } from "@recare/core/consts";
import { getErrorMessage } from "@recare/core/model/utils/errors";
import { TransitionalCareRequest } from "@recare/core/types";
import { FormElement, FormElementProps } from "@recare/react-forms-state";
import { useTranslations } from "@recare/translations";
import { useGetTransitionalCareRequests } from "apollo/hooks/queries";
import { composeProgress } from "apollo/utils";
import CheckboxInputField from "ds/components/CheckboxInputField";
import RSButton from "ds/components/RSButton";
import { VerticalLayout } from "ds/materials/layouts";
import { margin, padding, sizing } from "ds/materials/metrics";
import { REQUEST_LIST_ELEMENT_NAME } from "..";
import {
  RequestListContextState,
  RequestListProvider,
  useRequestListContext,
} from "../RequestListContext";
import { ACTION_TYPES } from "../RequestListContext/reducer";
import { EditCard } from "./EditCard";
import { RequestCard } from "./RequestCard";
import { SectionRow, GAP } from "dsl/ecosystems/PatientForms/components";

export const getSectionIdLabel = (id: number): string =>
  id < 10 ? `0${id}` : id.toString();

const HeaderSection = ({ auctionId }: { auctionId: number }) => {
  const { dispatch, state } = useRequestListContext();
  const translations = useTranslations();

  const [queryProgress, getRequests] = useGetTransitionalCareRequests({
    auctionId,
  });

  return (
    <SectionRow justify="space-between">
      <CheckboxInputField
        elementName="internet_portal_used"
        label={
          translations.patientForms.transitionalCareForm.sectionE
            .internetbasedPortals
        }
        labelStyleOverride={{ margin: margin(0) }}
        flatModel
      />
      <RSButton
        id="import_requests"
        loading={composeProgress([queryProgress], true)}
        style={{ margin: "0" }}
        color="primary"
        onClick={async () => {
          try {
            const requests = await getRequests();
            dispatch({
              type: ACTION_TYPES.IMPORT_REQUESTS,
              payload: {
                requests: requests ?? [],
              },
            });
          } catch (err) {
            console.error(
              `error importing transitional care requests - ${getErrorMessage(
                err,
              )}`,
            );
            return null;
          }
        }}
        variant="contained"
      >
        {state.request_list.some((r) => !r.manual)
          ? translations.patientForms.transitionalCareForm.sectionE
              .refreshButton
          : translations.patientForms.transitionalCareForm.sectionE
              .importButton}
      </RSButton>
    </SectionRow>
  );
};

export const showAddRequestsButton = (
  state: RequestListContextState,
): boolean => {
  if (state.adding) {
    return false;
  }
  if (state.request_list.length >= MAX_REQUESTS) {
    return false;
  }
  return true;
};

const AddRequestButton = () => {
  const { dispatch, state } = useRequestListContext();
  const translations = useTranslations();

  if (!showAddRequestsButton(state)) {
    return null;
  }

  return (
    <SectionRow justify="space-between">
      <RSButton
        id="add_request"
        loading="na"
        style={{ margin: "0" }}
        color="default"
        onClick={() => {
          dispatch({ type: ACTION_TYPES.OPEN_ADD });
        }}
        variant="contained"
        inverted
      >
        {
          translations.patientForms.transitionalCareForm.sectionE
            .addMoreProviders
        }
      </RSButton>
    </SectionRow>
  );
};

const RequestCards = () => {
  const { state } = useRequestListContext();

  if (!state.request_list.length) return null;

  return (
    <VerticalLayout padding={padding(GAP, 0, 0)} rowGap={sizing(1)}>
      {state.request_list.map((request, i) => {
        const displayId = i + 1;

        if (state.editing?.id === request.id) {
          return (
            <EditCard
              isEdit
              request={state.editing}
              displayId={displayId}
              key={request.id}
            />
          );
        }

        return (
          <RequestCard id={displayId} key={request.id} request={request} />
        );
      })}
    </VerticalLayout>
  );
};

const RequestListForm = ({ auctionId }: { auctionId: number }) => {
  const { state } = useRequestListContext();

  return (
    <>
      <HeaderSection auctionId={auctionId} />
      <RequestCards />
      <EditCard
        displayId={state.request_list.length + 1}
        request={state.adding}
      />
      <AddRequestButton />
    </>
  );
};

type RequestListProps = {
  auctionId: number;
  onChange: (
    value: TransitionalCareRequest[],
    statePath: typeof REQUEST_LIST_ELEMENT_NAME,
    validation: any,
  ) => void;
  value: TransitionalCareRequest[] | null;
} & FormElementProps;

const RequestList = ({ auctionId, onChange, value }: RequestListProps) => {
  const handleSubmit = (requests: TransitionalCareRequest[]) =>
    onChange(requests, REQUEST_LIST_ELEMENT_NAME, null);

  return (
    <RequestListProvider handleSubmit={handleSubmit} formValue={value || []}>
      <RequestListForm auctionId={auctionId} />
    </RequestListProvider>
  );
};

export const RequestListElement = FormElement()<RequestListProps>(RequestList);

import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";
import SendIcon from "@mui/icons-material/Send";
import { IconButton } from "@mui/material";
import {
  CHECKBOX_SPACE,
  DISABLED_SEARCH_ACTION_SEND_REQUESTS_ATTRIBUTED,
  DISABLED_SEARCH_ACTION_SEND_REQUESTS_CANCELLED,
  DISABLED_SEARCH_ACTION_SEND_REQUESTS_NOT_STARTED,
  REQUEST_CHIP_STATUS_NEW_FILE,
  REQUEST_CHIP_STATUS_NEW_MESSAGE,
  REQUEST_CHIP_STATUS_ONGOING_CHAT,
  REQUEST_CHIP_STATUS_REQUEST_SEEN,
  REQUEST_CHIP_STATUS_SENT_MESSAGE_SEEN,
  SEARCH_ACTION_SEND_REQUEST,
} from "@recare/core/consts";
import { getSearchActions } from "@recare/core/model/auctions";
import { useGetOntology } from "@recare/core/model/utils/ontologies/hooks";
import { RequestChipStatus } from "@recare/core/types";
import { useTranslations } from "@recare/translations";
import { CheckboxInputFieldPresenter } from "ds/components/CheckboxInputField";
import InfoChip, { InfoChipProps } from "ds/components/InfoChip";
import Tooltip from "ds/components/Tooltip";
import { GREY_500, UNAVAILABLE_GREY } from "ds/materials/colors";
import { HorizontalLayout } from "ds/materials/layouts";
import { dp } from "ds/materials/metrics";
import { FONT_SIZE_16 } from "ds/materials/typography";
import { usePrint } from "dsl/atoms/Contexts";
import { usePotentialReceiverContext } from "dsl/ecosystems/PatientSearchMerge/Components/SearchPageTables/Tables/PotentialReceiverTable/PotentialReceiverContext";
import { getCheckboxSettings } from "dsl/ecosystems/PatientSearchMerge/Components/SearchPageTables/Tables/shared";
import { useHasOverFlown } from "dsl/hooks";
import { useState } from "react";
import styled from "styled-components";
import { CellProps, DefaultTextCell } from "..";
import { DefaultCellBodyText } from "../../styles";
import {
  CONFIRM_FAX_GDPR,
  CONFIRM_SEND,
  ContactAnywayConfirmationModal,
  ContactAnywayModals,
} from "./ConfirmSendModals";

// Search Tables specific column type
export const COLUMN_TYPE_SEARCH_TABLE_PREFERRED_INPUT =
  "preferred_input" as const;
export const COLUMN_TYPE_SEARCH_TABLE_PREFERRED_INPUT_POTENTIAL_RECEIVERS =
  "preferred_input_potential_receivers" as const;
export const COLUMN_TYPE_SEARCH_TABLE_NAME_WITH_ICONS =
  "name_with_icons" as const;
export const COLUMN_TYPE_SEARCH_TABLE_STATUS = "status" as const;
export const COLUMN_TYPE_SEARCH_TABLE_CHECKBOX = "checkbox" as const;
export const COLUMN_TYPE_SEARCH_TABLE_INDENT = "indent" as const;
export const COLUMN_TYPE_SEARCH_TABLE_CONTACT_ANYWAY = "contact_anway" as const;

export function ProviderNameWithIcons({
  name,
  patient_data_shared,
  testId,
}: CellProps<typeof COLUMN_TYPE_SEARCH_TABLE_NAME_WITH_ICONS>) {
  const translations = useTranslations();
  const print = usePrint();

  if (print) return <DefaultCellBodyText>{name}</DefaultCellBodyText>;

  return (
    <HorizontalLayout
      style={{ gap: dp(6), alignItems: "start", width: "100%" }}
    >
      <DefaultTextCell testId={testId} message={name} />
      {patient_data_shared && (
        <Tooltip title={translations.search.accessToPatientDataGrantedTooltip}>
          <BadgeOutlinedIcon
            data-testid="patient-shared-icon"
            htmlColor={GREY_500}
            style={{ fontSize: FONT_SIZE_16, paddingTop: dp(4) }}
          />
        </Tooltip>
      )}
    </HorizontalLayout>
  );
}

type ChipColorConfig = {
  color: InfoChipProps["color"];
  variant: InfoChipProps["variant"];
};

function chipColorConfig(value: RequestChipStatus): ChipColorConfig {
  const defaultColor: ChipColorConfig = {
    color: "info",
    variant: "outlined",
  };

  switch (value) {
    case REQUEST_CHIP_STATUS_SENT_MESSAGE_SEEN:
    case REQUEST_CHIP_STATUS_REQUEST_SEEN:
    case REQUEST_CHIP_STATUS_ONGOING_CHAT:
      return {
        color: "success",
        variant: "outlined",
      };
    case REQUEST_CHIP_STATUS_NEW_MESSAGE:
    case REQUEST_CHIP_STATUS_NEW_FILE:
      return {
        color: "info",
        variant: "filled",
      };
    default:
      return defaultColor;
  }
}

const StatusLabel = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
`;

export function StatusValue({
  value,
}: CellProps<typeof COLUMN_TYPE_SEARCH_TABLE_STATUS>) {
  const getOntology = useGetOntology();
  const { color, variant } = chipColorConfig(value);
  const message = getOntology({
    key: value ?? 0,
    type: "searchTableStatusChips",
  });

  const { hasOverFlown, ref } = useHasOverFlown<HTMLDivElement>({
    direction: "x",
    value: message,
  });

  return (
    <InfoChip
      label={<StatusLabel ref={ref}>{message}</StatusLabel>}
      color={color}
      variant={variant}
      tooltip={hasOverFlown ? message : ""}
    />
  );
}

export function SelectProviderCheckbox({
  disabled,
  value,
}: CellProps<typeof COLUMN_TYPE_SEARCH_TABLE_CHECKBOX>) {
  const {
    getRequestState,
    requestListState: { automaticSearch },
  } = usePotentialReceiverContext();

  const checked =
    !!getRequestState(value.id)?.manually_selected || automaticSearch;

  return (
    <div
      style={{ textOverflow: "" }}
      onClick={(e) => {
        // the row is the clickable element not the checkbox in this case
        e.preventDefault();
      }}
    >
      <CheckboxInputFieldPresenter
        label=""
        elementName={`careprovider-${value.careprovider_id}`}
        checkboxSpace={CHECKBOX_SPACE.NONE}
        disabled={disabled}
        value={checked}
      />
    </div>
  );
}

export function AddColumnIndent() {
  return <div style={{ textOverflow: "" }} />;
}

export function ContactAnywayAction({
  auction,
  careprovider,
  recommendationId,
  refresh,
  sendFax,
}: CellProps<typeof COLUMN_TYPE_SEARCH_TABLE_CONTACT_ANYWAY>) {
  const [modalOpen, setModalOpen] = useState<ContactAnywayModals | null>(null);
  const translations = useTranslations();

  const { [SEARCH_ACTION_SEND_REQUEST]: canSendRequest } =
    getSearchActions(auction);

  if (!canSendRequest) return null;
  if (!recommendationId) return null;

  const { disableRow } = getCheckboxSettings({ auction });

  const tooltip = (() => {
    switch (canSendRequest?.context?.disabled) {
      case DISABLED_SEARCH_ACTION_SEND_REQUESTS_ATTRIBUTED:
        return translations.search.sendRequestCta.contactAnywaySearchWon;
      case DISABLED_SEARCH_ACTION_SEND_REQUESTS_CANCELLED:
        return translations.search.sendRequestCta.contactAnywaySearchStopped;
      case DISABLED_SEARCH_ACTION_SEND_REQUESTS_NOT_STARTED:
        return translations.search.sendRequestCta.contactAnywaySearchNotStarted;
      default:
        return translations.search.sendRequestCta.contactAnywaySearchRunning;
    }
  })();

  return (
    <>
      <Tooltip title={tooltip} placement="bottom-start">
        <div>
          <IconButton
            data-testid={`contact-anyway-${careprovider.id}`}
            size="medium"
            disabled={disableRow}
            aria-disabled={disableRow}
            onClick={() => {
              if (sendFax) {
                setModalOpen(CONFIRM_FAX_GDPR);
              } else {
                setModalOpen(CONFIRM_SEND);
              }
            }}
            aria-label={tooltip}
            color="default"
            sx={{
              "&:disabled": { color: UNAVAILABLE_GREY },
            }}
          >
            <SendIcon />
          </IconButton>
        </div>
      </Tooltip>
      <ContactAnywayConfirmationModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        recommendationId={recommendationId}
        auction={auction}
        refresh={refresh}
        sendFax={sendFax}
        careprovider={careprovider}
      />
    </>
  );
}

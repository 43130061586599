import { FAX_TYPE_REQUEST, LOGO_MASTER } from "@recare/core/consts";
import { getName } from "@recare/core/model/accounts";
import { Account, Auction, GetOntologyType } from "@recare/core/types";
import { useTranslations } from "@recare/translations";
import { TranslationComposition } from "@recare/translations/helpers";
import Translations from "@recare/translations/types";
import { RecareNameLogo } from "ds/components/RecareLogo";
import { TEXT_DARK_PRIMARY } from "ds/materials/colors";
import { dp, margin, padding } from "ds/materials/metrics";
import {
  FONT_FAMILY,
  FONT_SIZE_15,
  FONT_SIZE_20,
  FONT_SIZE_28,
  FONT_WEIGHT_BOLD,
  FONT_WEIGHT_REGULAR,
} from "ds/materials/typography";

const bodyStyle = ({
  bold = false,
  color,
  lineHeight,
  marginProp,
}: {
  bold?: boolean;
  color?: string;
  large?: boolean;
  lineHeight?: string;
  marginProp?: string;
}) => {
  return {
    fontFamily: FONT_FAMILY,
    fontSize: FONT_SIZE_15,
    lineHeight: lineHeight || "2",
    color: color || TEXT_DARK_PRIMARY,
    margin: marginProp || margin(0, 2),
    fontWeight: bold ? FONT_WEIGHT_BOLD : FONT_WEIGHT_REGULAR,
  };
};

const subheadingStyle = {
  fontFamily: FONT_FAMILY,
  fontWeight: FONT_WEIGHT_BOLD,
  fontSize: FONT_SIZE_20,
  color: TEXT_DARK_PRIMARY,
  margin: margin(2),
  lineHeight: "1.5",
};

const InformationSlug = ({ info, label }: { info: string; label: string }) => {
  return info ? (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "stretch",
        justifyContent: "flex-start",
      }}
    >
      <span
        style={{
          ...bodyStyle({
            marginProp: margin(0, 0, 0, 2),
            lineHeight: "1.5",
          }),
        }}
      >
        {label}
      </span>
      <span
        style={{
          ...bodyStyle({
            marginProp: margin(0, 2, 0, 0.32),
            lineHeight: "1.5",
          }),
        }}
      >
        {info}
      </span>
    </div>
  ) : null;
};

const Recarelogo = ({
  patientId,
  translations,
}: {
  patientId: string;
  translations: Translations;
}) => {
  return (
    <div
      style={{
        objectFit: "contain",
        padding: padding(1, 2, 1, 2),
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <RecareNameLogo
        variant={LOGO_MASTER}
        style={{ height: dp(50), position: "relative" }}
        noLazyLoad
      />
      <p
        style={{
          ...bodyStyle({
            marginProp: margin(0),
            bold: true,
          }),
        }}
      >
        {translations.faxRequest.recareId({ patientId })}
      </p>
    </div>
  );
};

const Header = ({
  isRequest,
  patientId,
  senderName,
  translations,
}: {
  isRequest: boolean;
  patientId: string;
  senderName: string;
  translations: Translations;
}) => {
  return (
    <>
      <div
        style={{
          fontFamily: FONT_FAMILY,
          fontSize: FONT_SIZE_28,
          lineHeight: 1.1,
          color: "#212121",
          margin: margin(2, 2, 3, 2),
          fontWeight: FONT_WEIGHT_BOLD,
        }}
      >
        {isRequest
          ? translations.faxRequest.titleNew
          : translations.faxRequest.coverPage.titlePatientValidation}
      </div>
      <div style={subheadingStyle}>
        {translations.faxRequest.fromSender({
          clinicName: senderName,
        })}
      </div>
      <div style={{ margin: margin(0, 2), lineHeight: "1.5" }}>
        <div
          style={{
            ...bodyStyle({
              marginProp: margin(0),
            }),
          }}
        >
          <p
            style={{
              ...bodyStyle({
                marginProp: margin(0, 0, 1),
              }),
            }}
          >
            {translations.faxRequest.faxIntroSalutation}
          </p>
          <p
            style={{
              ...bodyStyle({
                marginProp: margin(0, 0, 1),
              }),
            }}
          >
            {isRequest
              ? translations.faxRequest.faxIntroBodyOne({ patientId })
              : translations.faxRequest.coverPage.infoBodyTextValidation}
          </p>
        </div>
        {isRequest ? (
          <p
            style={{
              ...bodyStyle({
                marginProp: margin(0, 0, 1),
              }),
            }}
          >
            {translations.faxRequest.faxIntroBodyTwo}
          </p>
        ) : (
          <TranslationComposition
            translations={
              translations.faxRequest.coverPage.infoTextPatientValidation
            }
            withOptions
          >
            {([before, , after]) => (
              <span
                style={{
                  ...bodyStyle({
                    marginProp: margin(0),
                  }),
                }}
              >
                {before}
                <span
                  style={{
                    ...bodyStyle({
                      bold: true,
                      marginProp: margin(0),
                    }),
                  }}
                >
                  {patientId}
                </span>
                {after}
              </span>
            )}
          </TranslationComposition>
        )}
      </div>
    </>
  );
};

const SubHeading = ({
  senderName,
  translations,
}: {
  senderName: string;
  translations: Translations;
}) => {
  return (
    <>
      <div style={subheadingStyle}>
        {translations.faxRequest.coverPage.hospitalInformation.title}
      </div>
      <div
        style={{
          ...bodyStyle({
            large: true,
            bold: true,
          }),
        }}
      >
        {senderName}
      </div>
    </>
  );
};

const ContactBox = ({
  contact,
  contactPhone = "",
  getOntology,
  senderName,
  translations,
}: {
  contact: Account | undefined;
  contactPhone: string | undefined;
  getOntology: GetOntologyType;
  senderName: string;
  translations: Translations;
}) => {
  const contactPerson = getName(contact, getOntology);
  const email = contact?.email || "";

  return (
    <div
      style={{
        margin: margin(4, 0),
      }}
    >
      <SubHeading translations={translations} senderName={senderName} />
      <InformationSlug
        label={
          translations.faxRequest.coverPage.hospitalInformation.contactPerson
        }
        info={contactPerson}
      />
      <InformationSlug
        label={
          translations.faxRequest.coverPage.hospitalInformation.telephoneNumber
        }
        info={contactPhone}
      />
      <InformationSlug
        label={translations.faxRequest.coverPage.hospitalInformation.email}
        info={email}
      />
    </div>
  );
};

const PageAligner = ({ children }: { children: any }) => {
  return (
    <div
      style={{
        padding: padding(2, 0, 0, 0),
      }}
    >
      {children}
    </div>
  );
};

const MainAligner = ({ children }: { children: any }) => {
  return (
    <div
      style={{
        margin: margin(0, 2),
      }}
    >
      {children}
    </div>
  );
};

export default function FaxCoverLetter({
  auction,
  faxType,
  getOntology,
}: {
  auction: Auction;
  faxType: number;
  getOntology: GetOntologyType;
}) {
  const translations = useTranslations();
  const isRequest = faxType === FAX_TYPE_REQUEST;
  const patientId = auction?.patient?.user_id || "";
  const socialWorkerContact = auction?.patient?.social_worker;

  const senderName = auction?.patient?.careseeker?.name || "";

  return (
    <PageAligner>
      <MainAligner>
        <Recarelogo translations={translations} patientId={patientId} />
        <Header
          isRequest={isRequest}
          patientId={patientId}
          translations={translations}
          senderName={senderName}
        />
        <ContactBox
          getOntology={getOntology}
          translations={translations}
          contactPhone={socialWorkerContact?.phone}
          contact={socialWorkerContact}
          senderName={senderName}
        />
      </MainAligner>
    </PageAligner>
  );
}

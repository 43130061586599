import { useEffect, useLayoutEffect } from "react";
import { useSearchParams } from "react-router-dom";

export const useClearSearchParams = (paramKeys: string[]) => {
  const [search, setSearch] = useSearchParams();

  useEffect(() => {
    let shouldUpdateSearch = false;
    paramKeys.forEach((paramKey) => {
      if (search.has(paramKey)) {
        search.delete(paramKey);
        shouldUpdateSearch = true;
      }
    });
    if (shouldUpdateSearch) setSearch(search);
  }, []);
};

export function removeParam(
  param: string,
  location: { pathname: string; search: string },
) {
  const search = location.search;
  const params = new URLSearchParams(search);
  params.delete(param);
  const paramsAsString = params.toString();
  if (paramsAsString.length) {
    return location.pathname + "?" + paramsAsString;
  }
  return location.pathname;
}

export default function useRemoveQueryParam(param: string) {
  useLayoutEffect(() => {
    window.history.replaceState({}, "", removeParam(param, window.location));
  }, []);
}

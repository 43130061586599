import {
  convertModelDefinition,
  listDef,
  valueDef,
} from "@recare/react-forms-state";

export const transitionalCareModelDefinition = convertModelDefinition({
  // form creation date
  ...valueDef("handover_to_insurance_date"),

  // clinic section
  ...valueDef("clinic_name"),
  ...valueDef("clinic_ik_number"),
  ...valueDef("clinic_location_street"),
  ...valueDef("clinic_location_street_number"),
  ...valueDef("clinic_location_zipcode"),
  ...valueDef("clinic_location_city"),

  // social worker section
  ...valueDef("social_worker_last_name"),
  ...valueDef("social_worker_first_name"),
  ...valueDef("social_worker_telephone"),
  ...valueDef("social_worker_fax"),
  ...valueDef("social_worker_email"),

  // patient contact section
  ...valueDef("patient_last_name"),
  ...valueDef("patient_first_name"),
  ...valueDef("patient_date_of_birth"),
  ...valueDef("patient_insurance_number"),
  ...valueDef("patient_location_street"),
  ...valueDef("patient_location_house_number"),
  ...valueDef("patient_location_zipcode"),
  ...valueDef("patient_location_city"),

  // legal representation section
  ...valueDef("legal_representative_consent_provided"),
  ...valueDef("legal_representative_last_name"),
  ...valueDef("legal_representative_first_name"),
  ...valueDef("legal_representative_location_street"),
  ...valueDef("legal_representative_location_street_number"),
  ...valueDef("legal_representative_location_zipcode"),
  ...valueDef("legal_representative_location_city"),

  // prior treatment section
  ...valueDef("prior_treatment_start_date"),
  ...valueDef("prior_treatment_end_date"),
  ...valueDef("prior_treatment_hospital_id"),

  // transitional care section
  ...valueDef("transitional_care_start_date"),
  ...valueDef("transitional_care_end_date"),
  ...valueDef("transitional_care_location_id"),
  ...valueDef("transitional_care_department_code"),

  // section a
  ...valueDef("transitional_care_start_search_date"),

  // section b
  ...valueDef("transitional_care_patient_confirmation"),
  ...valueDef("transitional_care_patient_confirmation_date"),
  ...valueDef("transitional_care_patient_confirmation_explanation"),
  ...valueDef("transitional_care_description"),
  ...valueDef("transitional_care_suitable_providers_more_than_twenty"),
  ...valueDef("transitional_care_suitable_providers_count", {
    convertIn: (value) => value ?? 0,
  }),

  // section c
  ...valueDef("aftercare_mobile"),
  ...valueDef("aftercare_shortterm_care"),
  ...valueDef("aftercare_medical_rehab"),
  ...valueDef("aftercare_application_submitted"),
  ...valueDef("aftercare_application_date"),
  ...valueDef("aftercare_application_explanation"),
  ...valueDef("aftercare_care_level_aftercare_required"),
  ...valueDef("aftercare_care_level_available"),
  ...valueDef("aftercare_care_level_applied"),
  ...valueDef("aftercare_care_level_applied_date"),
  ...valueDef("aftercare_care_level_explanation"),

  // section d
  ...valueDef("insurance_form_sent_date"),

  // section e
  ...valueDef("internet_portal_used", { defaultValue: true }),
  ...listDef("request_list"),

  // section f
  ...valueDef("care_necessity"),
  ...valueDef("aftercare_start_date"),
});

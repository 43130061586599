import CloseIcon from "@mui/icons-material/Close";
import { Divider, IconButton } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import { getKey } from "@recare/core/model/utils/strings";
import { HorizontalLayout, VerticalLayout } from "ds/materials/layouts";
import { dp, margin, padding, sizing } from "ds/materials/metrics";
import { Body, Subheading } from "ds/materials/typography";
import { Dispatch, SetStateAction } from "react";
import styled from "styled-components";

type StyledPaperProps = {
  $drawerOpen: boolean;
  $isTablet: boolean;
  $offset?: string;
};

export const DRAWER_WIDTH = 300;

const StyledPaperDrawerPlaceHolder = styled.div<StyledPaperProps>`
  box-sizing: border-box;
  height: ${({ $offset }) => `calc(100vh - ${$offset})`};
  width: ${getDrawerWidth};
`;

export const DrawerLayout = styled.div<{
  $drawerOpen: boolean;
  $isTablet: boolean;
}>`
  align-items: center;
  box-sizing: border-box;
  display: ${({ $drawerOpen, $isTablet }) =>
    $isTablet && $drawerOpen ? "none" : "flex"};
  flex-direction: column;
  margin: ${({ $isTablet }) =>
    $isTablet ? 0 : `${margin(2)} auto ${margin(10)}`};
  max-width: ${({ $isTablet }) => ($isTablet ? "100%" : getLayoutMaxWidth)};
  width: 100%;
`;

export function getLayoutMaxWidth({
  $drawerOpen,
  $isTablet,
}: {
  $drawerOpen?: boolean;
  $isTablet?: boolean;
}) {
  if ($drawerOpen) {
    return $isTablet ? dp(0) : `calc(80vw - ${dp(DRAWER_WIDTH)})`;
  }
  return $isTablet ? "95%" : "80vw";
}

function getDrawerWidth({
  $drawerOpen,
  $isTablet,
}: {
  $drawerOpen?: boolean;
  $isTablet?: boolean;
}) {
  if ($drawerOpen) {
    return $isTablet ? "100vw" : dp(DRAWER_WIDTH);
  }
  return dp(0);
}

export default function CustomDrawer({
  isDrawerOpen,
  isTablet,
  offset,
  sections,
  setDrawerOpen,
  title,
}: {
  isDrawerOpen: boolean;
  isTablet: boolean;
  offset?: string;
  sections: { description: string; title: string }[];
  setDrawerOpen: Dispatch<SetStateAction<boolean>>;
  title: string;
}) {
  return (
    <>
      <Drawer
        anchor="right"
        disablePortal
        hideBackdrop
        open={isDrawerOpen}
        PaperProps={{
          square: true,
          elevation: 0,
          sx: {
            boxSizing: "border-box",
            height: `calc(100vh - ${offset})`,
            marginTop: offset,
            overflowX: "hidden",
            overflowY: "auto",
            paddingBottom: padding(2),
            width: isTablet ? "100vw" : dp(DRAWER_WIDTH),
          },
        }}
        variant="persistent"
      >
        <HorizontalLayout
          boxSizing="border-box"
          gap={sizing(2)}
          width={isTablet ? "100vw" : dp(DRAWER_WIDTH)}
        >
          <VerticalLayout gap={sizing(1.5)} margin="0" width="100%">
            <HorizontalLayout
              boxSizing="border-box"
              justify="space-between"
              padding={padding(2, 2, 0, 2)}
              width="100%"
            >
              <Subheading margin={margin(1, 0)} bold>
                {title}
              </Subheading>
              <div>
                <IconButton onClick={() => setDrawerOpen(false)}>
                  <CloseIcon />
                </IconButton>
              </div>
            </HorizontalLayout>
            <Divider />
            {sections.map(({ description, title }, index) => (
              <HorizontalLayout
                maxWidth="90%"
                padding={padding(0, 2)}
                key={getKey(title, index)}
              >
                <Body
                  breakWord
                  margin="0"
                  maxWidth={isTablet ? dp(650) : "90%"}
                >
                  <b>{title}</b>
                  <br />
                  {description}
                </Body>
              </HorizontalLayout>
            ))}
          </VerticalLayout>
        </HorizontalLayout>
      </Drawer>
      <StyledPaperDrawerPlaceHolder
        $drawerOpen={isDrawerOpen}
        $isTablet={isTablet}
        $offset={offset}
      />
    </>
  );
}

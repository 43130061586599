import { useSafeState } from "@recare/core/hooks";
import { getLocale } from "@recare/core/model/utils/dates";
import { useLocale } from "@recare/translations";
import { de } from "date-fns/locale";
import { useEffect } from "react";

export const useDateLocale = () => {
  const [currentLocale, setCurrentLocale] = useSafeState(de);
  const locale = useLocale();

  const setDateLocale = () => {
    const selectedLocale = getLocale(locale);
    setCurrentLocale(selectedLocale);
  };

  useEffect(() => {
    if (currentLocale.code?.replace("-GB", "") !== locale) {
      setDateLocale();
    }
  }, [locale]);

  return { currentLocale };
};

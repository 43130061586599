import { PAGINATION_COUNT_15, PAGINATION_START_0 } from "@recare/core/consts";
import {
  DeepNullableObj,
  FilterValuePaginationCount,
  FilterValuePaginationStart,
  FilterValueSenders,
  FilterValueStartDateRange,
  Interval,
} from "@recare/core/types";
import {
  USER_FILTER_AGE_INTERVAL,
  USER_FILTER_DISTANCE_FROM_PROVIDER,
  USER_FILTER_ELECTIVE,
  USER_FILTER_HAS_CAPACITY,
  USER_FILTER_IS_DIRECT,
  USER_FILTER_SENDERS,
  USER_FILTER_START_DATE,
  USER_FILTER_START_DATE_PARAM_FROM,
  USER_FILTER_START_DATE_PARAM_TO,
  USER_FILTER_TAB,
  USER_FILTER_TRANSITIONAL_CARE,
  USER_FILTER_TREATMENT_LOCATION,
  USER_FILTER_WEIGHT_INTERVAL,
  USER_FILTER_WITH_CONSULTANTS,
  USER_FILTER_WITH_PROVIDERS,
  toArrayParamName,
} from "dsl/organisms/Filters/consts";
import { ArrayFilterName, UserFilters } from "dsl/organisms/Filters/types";

export type SetKeyBasicValueQueryParamProps = {
  filterName:
    | ArrayFilterName
    | Extract<
        keyof UserFilters,
        | typeof USER_FILTER_DISTANCE_FROM_PROVIDER
        | typeof USER_FILTER_START_DATE
        | typeof USER_FILTER_TAB
        | typeof USER_FILTER_TREATMENT_LOCATION
      >;
  filterValue: number | string | undefined;
};

export const setKeyBasicValueQueryParam = ({
  filterName,
  filterValue,
}: SetKeyBasicValueQueryParamProps): string => {
  if (!filterValue) return "";
  const arrayParamName =
    toArrayParamName?.[filterName as keyof typeof toArrayParamName];
  const filterParamName = arrayParamName ? arrayParamName : filterName;

  return `${filterParamName}=${filterValue}`;
};

export type BasicArrayTypeNames = Exclude<
  ArrayFilterName,
  typeof USER_FILTER_SENDERS
>;

export type SetBasicArrayFilterParamProps = {
  filterName: BasicArrayTypeNames;
  filters: Partial<UserFilters>;
};

export const setBasicArrayFilterParam = ({
  filterName,
  filters,
}: SetBasicArrayFilterParamProps): string => {
  const filterValues = filters?.[filterName];
  if (!filterValues || !Array.isArray(filterValues)) {
    return "";
  }
  const query: string[] = [];
  filterValues.forEach((filterValue: number | string) => {
    query.push(
      setKeyBasicValueQueryParam({
        filterName,
        filterValue,
      }),
    );
  });
  return query.truthy().join("&");
};

export type SetBooleanQueryParamProps = {
  filterName: Extract<
    keyof UserFilters,
    | typeof USER_FILTER_ELECTIVE
    | typeof USER_FILTER_TRANSITIONAL_CARE
    | typeof USER_FILTER_HAS_CAPACITY
    | typeof USER_FILTER_IS_DIRECT
    | typeof USER_FILTER_WITH_CONSULTANTS
    | typeof USER_FILTER_WITH_PROVIDERS
  >;
  filters: Partial<UserFilters>;
};

export const setBooleanQueryParam = ({
  filterName,
  filters,
}: SetBooleanQueryParamProps): string => {
  const filterValue = !!filters?.[filterName];
  if (!filterValue) return "";
  return `${filterName}`;
};

export const setSendersFilterQueryParam = (
  filterValue: FilterValueSenders | undefined,
) => {
  if (!filterValue || !Array.isArray(filterValue) || !filterValue?.length) {
    return "";
  }
  return filterValue
    .map(({ id, label }) =>
      setKeyBasicValueQueryParam({
        filterName: USER_FILTER_SENDERS,
        filterValue: `${id}-${encodeURIComponent(label)}`,
      }),
    )
    .truthy()
    .join("&");
};

export const setStartDateRangeQueryParam = (
  filterValue: FilterValueStartDateRange | undefined,
) => {
  const fromDate = filterValue?.from_date
    ? `${USER_FILTER_START_DATE_PARAM_FROM}=${filterValue.from_date}`
    : "";

  const endDate = filterValue?.to_date
    ? `${USER_FILTER_START_DATE_PARAM_TO}=${filterValue.to_date}`
    : "";

  if (fromDate && endDate) return `${fromDate}&${endDate}`;

  return `${fromDate}${endDate}`;
};

export const setIntervalQueryParam = <
  I extends DeepNullableObj<Interval> | undefined,
>(
  filterType:
    | typeof USER_FILTER_AGE_INTERVAL
    | typeof USER_FILTER_WEIGHT_INTERVAL,
  filterValue: I,
  defaults?: { max: number; min: number },
) => {
  if (
    (!filterValue || (filterValue.max == null && filterValue.min == null)) &&
    !defaults
  ) {
    return "";
  }

  const fromMinimumAge = filterValue?.min
    ? `=min${filterValue.min}`
    : defaults
    ? `=min${defaults.min}`
    : "";

  const toMaxAge = filterValue?.max
    ? `max${filterValue.max}`
    : defaults
    ? `max${defaults.max}`
    : "";

  return `${filterType}${fromMinimumAge}${toMaxAge}`;
};

export const setPaginationStartQueryParam = (
  filterPaginationStart: FilterValuePaginationStart | undefined,
) => {
  const start = filterPaginationStart
    ? `start=${filterPaginationStart}`
    : `start=${PAGINATION_START_0}`;

  return start;
};

export const setPaginationCountQueryParam = (
  filterPaginationCount: FilterValuePaginationCount | undefined,
) => {
  const count = filterPaginationCount
    ? `count=${filterPaginationCount}`
    : `count=${PAGINATION_COUNT_15}`;

  return count;
};

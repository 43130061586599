import { Pagination } from "@mui/material";
import {
  AUCTION_STATUS_NOT_STARTED,
  CHECKBOX_SIZE,
  DISABLED_SEARCH_ACTION_SEND_REQUESTS_ACCEPTED,
  DISABLED_SEARCH_ACTION_SEND_REQUESTS_ATTRIBUTED,
  DISABLED_SEARCH_ACTION_SEND_REQUESTS_CANCELLED,
  DISABLED_SEARCH_ACTION_SEND_REQUESTS_TRANSPORT_LIMIT,
  QUERY_PROGRESS_PENDING,
  SEARCH_ACTION_SEND_REQUEST,
  SEARCH_ACTION_START_AUTOMATIC_SEARCH,
  SEARCH_ACTION_START_MANUAL_SEARCH,
  SEARCH_TABLE_ACCEPTED,
  SEARCH_TABLE_CONTACTED,
  SEARCH_TABLE_DECLINED,
  SEARCH_TABLE_REJECTED,
  SEARCH_TABLE_ROW_COUNT_10,
  SEARCH_TABLE_VALIDATED,
  SEARCH_TYPE_HOSPITAL,
} from "@recare/core/consts";
import {
  declineDetailsReason,
  findSearchAction,
} from "@recare/core/model/auctions";
import { formatDistance } from "@recare/core/model/utils/location";
import {
  ONTOLOGY_ROWS_PER_PAGE,
  ONTOLOGY_SOLUTION,
  ONTOLOGY_SPECIALIZATIONS,
} from "@recare/core/model/utils/ontologies";
import { useGetOntologiesForSelect } from "@recare/core/model/utils/ontologies/hooks";
import { grammaticalList } from "@recare/core/model/utils/strings";
import {
  AcceptedTableRequest,
  Auction,
  ContactedTableRequest,
  DeclinedTableRequest,
  DisabledSearchActionType,
  FilteredTableFilteredCandidate,
  GetOntologyType,
  PotentialReceiversTableCandidate,
  QueryProgress,
  RejectedTableRequest,
  SearchMergeTableRequest,
  SearchPanelRequest,
  SearchTable,
  TableCount,
  TablePageState,
  TourElement,
  ValidatedTableRequest,
} from "@recare/core/types";
import { Locale as LocaleString, useTranslations } from "@recare/translations";
import Translations from "@recare/translations/types";
import { format, fromUnixTime } from "date-fns";
import { CheckboxInputFieldPresenter } from "ds/components/CheckboxInputField";
import { SelectInput } from "ds/components/SelectInput";
import {
  COLUMN_TYPE_DEFAULT_DATE,
  COLUMN_TYPE_DEFAULT_STRING,
  GeneralTableColumn,
} from "ds/components/Tables/GeneralTable/CustomCells";
import {
  COLUMN_TYPE_SEARCH_TABLE_CHECKBOX,
  COLUMN_TYPE_SEARCH_TABLE_CONTACT_ANYWAY,
  COLUMN_TYPE_SEARCH_TABLE_INDENT,
  COLUMN_TYPE_SEARCH_TABLE_NAME_WITH_ICONS,
  COLUMN_TYPE_SEARCH_TABLE_PREFERRED_INPUT,
  COLUMN_TYPE_SEARCH_TABLE_PREFERRED_INPUT_POTENTIAL_RECEIVERS,
  COLUMN_TYPE_SEARCH_TABLE_STATUS,
} from "ds/components/Tables/GeneralTable/CustomCells/SearchTables";
import { HorizontalLayout } from "ds/materials/layouts";
import { dp, margin, sizing } from "ds/materials/metrics";
import {
  StopSearchWarning,
  getWarningMessage,
} from "dsl/ecosystems/PatientSearchMerge/Components/SearchActions/ActionModal/BackendCareproviderWarnings";
import { TablePageStateSetter } from "dsl/ecosystems/PatientSearchMerge/utils";
import { CareseekerNavigationHandlers } from "dsl/hooks";
import { PRODUCT_TOURS, tourAttributes } from "dsl/molecules/useProductTour";
import React, { CSSProperties } from "react";
import { usePotentialReceiverContext } from "./PotentialReceiverTable/PotentialReceiverContext";
import { RequestListState } from "./PotentialReceiverTable/PotentialReceiverTableReducer";

const PREFERRED_WIDTH = sizing(22);

export function formatDistanceForSearchTables(
  distance: number | null | undefined,
  translations: Translations,
): string {
  if (!distance) return "";

  return translations.search.searchPageTables.distanceToPatient({
    distance: formatDistance(distance),
  });
}

export function columnPotentialStartDate<Data extends AcceptedTableRequest>({
  auction,
  locale,
  translations,
}: {
  auction: Auction;
  locale: Locale;
  translations: Translations;
}): GeneralTableColumn<Data, typeof COLUMN_TYPE_DEFAULT_STRING> {
  return {
    title: translations.search.searchPageTables.columnNames.potentialStartDate,
    type: COLUMN_TYPE_DEFAULT_STRING,
    getProps: ({ potential_start_date, potential_start_time }) => {
      return {
        value: `${format(
          fromUnixTime(potential_start_date ?? auction.start_date),
          "PP",
          {
            locale,
          },
        )} ${potential_start_time || ""}`,
      };
    },
    width: "10%",
  };
}

export function columnRejectDate<Data extends RejectedTableRequest>({
  translations,
}: {
  translations: Translations;
}): GeneralTableColumn<Data, typeof COLUMN_TYPE_DEFAULT_DATE> {
  return {
    title: translations.search.searchPageTables.columnNames.rejectDate,
    type: COLUMN_TYPE_DEFAULT_DATE,
    getProps: ({ validation }) => ({
      value: validation.rejected_at,
      withTime: true,
    }),
    width: "15%",
  };
}

export function columnDeclineReason<Data extends DeclinedTableRequest>({
  auction,
  getOntology,
  locale,
  translations,
}: {
  auction: Auction;
  getOntology: GetOntologyType;
  locale: Locale;
  translations: Translations;
}): GeneralTableColumn<Data, typeof COLUMN_TYPE_DEFAULT_STRING> {
  return {
    title: translations.search.searchPageTables.columnNames.declineReason,
    type: COLUMN_TYPE_DEFAULT_STRING,
    getProps: ({ response }) => ({
      value:
        declineDetailsReason({
          getOntology,
          options: { __typename: "AuctionResponse", ...response },
          searchType: auction.search_type,
          translations,
          withComma: true,
          isSearchMergeTable: true,
          locale,
        }) ?? undefined,
    }),
    width: "15%",
  };
}

export function columnDeclineDate<Data extends DeclinedTableRequest>({
  translations,
}: {
  translations: Translations;
}): GeneralTableColumn<Data, typeof COLUMN_TYPE_DEFAULT_DATE> {
  return {
    title: translations.search.searchPageTables.columnNames.declineDate,
    type: COLUMN_TYPE_DEFAULT_DATE,
    getProps: ({ response }) => ({
      value: response.declined_at,
      withTime: true,
    }),
    width: "10%",
  };
}

export function columnFilterReason<
  Data extends FilteredTableFilteredCandidate,
>({
  getOntology,
  locale,
  localeString,
  translations,
  width,
}: {
  getOntology: GetOntologyType;
  locale: Locale;
  localeString: LocaleString;
  translations: Translations;
  width: CSSProperties["width"];
}): GeneralTableColumn<Data, typeof COLUMN_TYPE_DEFAULT_STRING> {
  return {
    title: translations.search.searchPageTables.columnNames.filterReason,
    type: COLUMN_TYPE_DEFAULT_STRING,
    getProps: ({ careprovider, solution_filter_reasons }) => {
      if (!careprovider || !solution_filter_reasons) return { value: "" };

      const warningMessages = Object.keys(solution_filter_reasons)
        .flatMap((filteredSolution) => {
          return solution_filter_reasons[filteredSolution].map((reason) => {
            return getWarningMessage({
              reason,
              careprovider,
              locale,
              localeString,
              translations,
              getOntology,
            });
          });
        })
        .truthy();

      const uniqueMessagesMap = new Map<string, StopSearchWarning>();
      warningMessages.forEach((messageObj) => {
        if (messageObj && !uniqueMessagesMap.has(messageObj.message)) {
          uniqueMessagesMap.set(messageObj.message, messageObj);
        }
      });

      const deduplicatedMessages = Array.from(uniqueMessagesMap.values());

      return {
        value: grammaticalList({
          array: deduplicatedMessages.map((messageObj) => messageObj.message),
          translations,
        }),
      };
    },
    width,
  };
}

export function columnStartDate<Data extends ValidatedTableRequest>({
  auction,
  locale,
  translations,
}: {
  auction: Auction;
  locale: Locale;
  translations: Translations;
}): GeneralTableColumn<Data, typeof COLUMN_TYPE_DEFAULT_STRING> {
  const dateFormat =
    auction.search_type === SEARCH_TYPE_HOSPITAL ? "PPp" : "PP";

  return {
    title: translations.search.searchPageTables.columnNames.startDate,
    type: COLUMN_TYPE_DEFAULT_STRING,
    getProps: () => ({
      value: auction.start_date
        ? `${format(fromUnixTime(auction.start_date), dateFormat, {
            locale,
          })}`
        : "",
    }),
    width: "10%",
  };
}

const getProviderName = ({
  auction,
  careprovider,
  getOntology,
  translations,
}: {
  auction: Auction;
  careprovider: SearchPanelRequest["careprovider"];
  getOntology: GetOntologyType;
  translations: Translations;
}) => {
  if (careprovider?.name) return careprovider?.name;

  if (auction.winner?.specialization) {
    const specialization = getOntology({
      key: auction.winner?.specialization,
      type: ONTOLOGY_SPECIALIZATIONS,
    });
    return `${translations.search.searchPageTables.manuallyAssignedWinner.internalTransfer} - ${specialization}`;
  }
  if (!auction.winner?.unknown_provider) {
    console.warn(
      `FE received no careprovider name and winner not unknown for auction - ${auction.id}`,
    );
  }

  return translations.search.searchPageTables.manuallyAssignedWinner
    .unknownProvider;
};

// Shared column functions
export function columnProviderNameWithIcons<
  Data extends
    | AcceptedTableRequest
    | ContactedTableRequest
    | ValidatedTableRequest,
>({
  auction,
  getOntology,
  translations,
}: {
  auction: Auction;
  getOntology: GetOntologyType;
  translations: Translations;
}): GeneralTableColumn<Data, typeof COLUMN_TYPE_SEARCH_TABLE_NAME_WITH_ICONS> {
  return {
    title: getOntology({
      type: "receiverTypeSingular",
      key: auction.search_type,
    }),
    type: COLUMN_TYPE_SEARCH_TABLE_NAME_WITH_ICONS,
    getProps: ({ careprovider, patient_data_shared }) => ({
      name: getProviderName({
        auction,
        careprovider,
        getOntology,
        translations,
      }),
      patient_data_shared,
      testId: `provider-name-cell-${careprovider?.id}`,
    }),
  };
}

export function columnProviderName<
  Data extends
    | DeclinedTableRequest
    | FilteredTableFilteredCandidate
    | PotentialReceiversTableCandidate
    | RejectedTableRequest,
>({
  auction,
  getOntology,
  width,
}: {
  auction: Auction;
  getOntology: GetOntologyType;
  width?: CSSProperties["width"];
}): GeneralTableColumn<Data, typeof COLUMN_TYPE_DEFAULT_STRING> {
  return {
    title: getOntology({
      type: "receiverTypeSingular",
      key: auction.search_type,
    }),
    type: COLUMN_TYPE_DEFAULT_STRING,
    getProps: ({ careprovider }) => ({
      value: careprovider?.name,
      testId: `provider-name-cell-${careprovider?.id}`,
    }),
    width,
  };
}

export function columnRequestDate<
  Data extends
    | ContactedTableRequest
    | DeclinedTableRequest
    | RejectedTableRequest,
>({
  translations,
}: {
  translations: Translations;
}): GeneralTableColumn<Data, typeof COLUMN_TYPE_DEFAULT_DATE> {
  return {
    title: translations.search.searchPageTables.columnNames.dateOfRequest,
    type: COLUMN_TYPE_DEFAULT_DATE,
    getProps: ({ created_at }) => ({
      value: created_at,
      withTime: true,
    }),
    width: "10%",
  };
}

export function columnDistance<
  Data extends
    | AcceptedTableRequest
    | ContactedTableRequest
    | FilteredTableFilteredCandidate
    | PotentialReceiversTableCandidate
    | ValidatedTableRequest,
>({
  isHidden,
  translations,
}: {
  isHidden: boolean;
  translations: Translations;
}): GeneralTableColumn<Data, typeof COLUMN_TYPE_DEFAULT_STRING> {
  return {
    title: translations.search.searchPageTables.columnNames.distance,
    getProps: ({ distance }) => ({
      value: distance
        ? formatDistanceForSearchTables(distance, translations)
        : translations.search.searchPageTables.manuallyAssignedWinner
            .dataNotAvailable,
    }),
    type: COLUMN_TYPE_DEFAULT_STRING,
    width: "10%",
    isHidden,
  };
}

export function columnStatus<
  Data extends
    | AcceptedTableRequest
    | ContactedTableRequest
    | DeclinedTableRequest
    | RejectedTableRequest,
>({
  translations,
}: {
  translations: Translations;
}): GeneralTableColumn<Data, typeof COLUMN_TYPE_SEARCH_TABLE_STATUS> {
  return {
    title: translations.search.searchPageTables.columnNames.status,
    tour: tourAttributes({
      tourKey: PRODUCT_TOURS.search_merge_started.key,
      stepKey: PRODUCT_TOURS.search_merge_started.steps.status.key,
    }),
    type: COLUMN_TYPE_SEARCH_TABLE_STATUS,
    getProps: ({ chip_status }) => ({ value: chip_status }),
    width: "15%",
  };
}

export function columnLocation<
  Data extends
    | AcceptedTableRequest
    | ContactedTableRequest
    | FilteredTableFilteredCandidate
    | PotentialReceiversTableCandidate
    | DeclinedTableRequest
    | RejectedTableRequest
    | ValidatedTableRequest,
>({
  translations,
  width,
}: {
  translations: Translations;
  width?: CSSProperties["width"];
}): GeneralTableColumn<Data, typeof COLUMN_TYPE_DEFAULT_STRING> {
  return {
    title: translations.search.searchPageTables.columnNames.location,
    type: COLUMN_TYPE_DEFAULT_STRING,
    getProps: ({ careprovider }) => ({
      value:
        careprovider?.address?.zip_code && careprovider?.address?.city
          ? `${careprovider?.address?.zip_code} ${careprovider?.address?.city}`
          : translations.search.searchPageTables.manuallyAssignedWinner
              .dataNotAvailable,
    }),
    width: width ?? "10%",
  };
}

export function columnPreferredInput<
  Data extends
    | ContactedTableRequest
    | DeclinedTableRequest
    | RejectedTableRequest
    | ValidatedTableRequest,
>({
  auction,
  invalidate,
  refresh,
  table,
  tour,
  translations,
}: {
  auction: Auction;
  invalidate?: () => void;
  refresh?: () => void;
  table: SearchTable;
  tour?: TourElement;
  translations: Translations;
}): GeneralTableColumn<Data, typeof COLUMN_TYPE_SEARCH_TABLE_PREFERRED_INPUT> {
  return {
    title: translations.search.patientWishInfoIcon,
    tour: tour,
    type: COLUMN_TYPE_SEARCH_TABLE_PREFERRED_INPUT,
    hint: translations.search.searchPageTables.columnNames.hint.patientWish,
    getProps: (auctionRequest) => ({
      auctionRequest,
      auction,
      table,
      refresh,
      invalidate,
    }),
    width: PREFERRED_WIDTH,
  };
}

export function columnPreferredInputPotentialReceivers<
  Data extends PotentialReceiversTableCandidate,
>({
  auction,
  table,
  translations,
}: {
  auction: Auction;
  table: SearchTable;
  translations: Translations;
}): GeneralTableColumn<
  Data,
  typeof COLUMN_TYPE_SEARCH_TABLE_PREFERRED_INPUT_POTENTIAL_RECEIVERS
> {
  return {
    title: translations.search.patientWishInfoIcon,
    tour: tourAttributes({
      tourKey: PRODUCT_TOURS.search_merge_not_started.key,
      stepKey: PRODUCT_TOURS.search_merge_not_started.steps.patient_wish.key,
    }),
    type: COLUMN_TYPE_SEARCH_TABLE_PREFERRED_INPUT_POTENTIAL_RECEIVERS,
    hint: translations.search.searchPageTables.columnNames.hint.patientWish,
    getProps: (auctionRequest) => ({
      auctionRequest,
      auction,
      table,
    }),
    width: PREFERRED_WIDTH,
  };
}

export function columnSolution<Data extends SearchMergeTableRequest>({
  getOntology,
  translations,
  width,
}: {
  getOntology: GetOntologyType;
  translations: Translations;
  width?: CSSProperties["width"];
}): GeneralTableColumn<Data, typeof COLUMN_TYPE_DEFAULT_STRING> {
  return {
    title: translations.search.searchPageTables.columnNames.solutions,
    type: COLUMN_TYPE_DEFAULT_STRING,
    getProps: ({ solutions }) => ({
      value: grammaticalList({
        array: solutions,
        ontologyType: ONTOLOGY_SOLUTION,
        getOntology,
        translations,
        useShortValues: true,
      }),
    }),
    width,
  };
}

export function columnIndentation<
  Data extends SearchMergeTableRequest,
>(): GeneralTableColumn<Data, typeof COLUMN_TYPE_SEARCH_TABLE_INDENT> {
  return {
    type: COLUMN_TYPE_SEARCH_TABLE_INDENT,
    getProps: () => {
      return {};
    },
    width: sizing(2.5),
  };
}

// Pagination component
export function TablePagination({
  currentTablePageState,
  setTablePageState,
  total,
}: {
  currentTablePageState: TablePageState;
  setTablePageState: TablePageStateSetter;
  total: number | undefined;
}) {
  const rowsPerPage = useGetOntologiesForSelect({
    type: ONTOLOGY_ROWS_PER_PAGE,
    sort: true,
  });

  if (!total || total <= SEARCH_TABLE_ROW_COUNT_10) return null;

  const totalPages = Math.ceil(total / currentTablePageState.count);

  return (
    <HorizontalLayout
      data-testid="table-pagination"
      justify="space-between"
      margin={margin(2.5, 0)}
      aligned
    >
      <Pagination
        page={currentTablePageState.page}
        count={totalPages}
        onChange={(e, page) =>
          setTablePageState({ ...currentTablePageState, page })
        }
      />
      <SelectInput
        style={{ width: dp(125) }}
        size="small"
        elementName="rowsPerPage"
        value={currentTablePageState.count}
        options={rowsPerPage}
        onChange={(e) =>
          setTablePageState({
            ...currentTablePageState,
            page: 1,
            count: e as TableCount,
          })
        }
        variant="outlined"
      />
    </HorizontalLayout>
  );
}

type ProviderCheckboxHeaderAriaTypes = {
  checked: boolean | "mixed";
  label: string;
};

const useGetCheckboxHeaderAriaType = ({
  automaticSearch,
  indeterminate,
}: {
  automaticSearch: boolean;
  indeterminate: boolean;
}): ProviderCheckboxHeaderAriaTypes => {
  const translations = useTranslations();

  if (indeterminate)
    return {
      label:
        translations.search.searchPageTables.accessibility
          .selectAllIntermediary,
      checked: "mixed",
    };

  if (automaticSearch)
    return {
      label: translations.search.searchPageTables.accessibility.deselectAll,
      checked: true,
    };

  return {
    label: translations.search.searchPageTables.accessibility.selectAll,
    checked: false,
  };
};

export function SelectProviderCheckboxHeader({
  disabled,
}: {
  disabled: boolean;
}) {
  const {
    deselectAllRequests,
    requestListState: { automaticSearch, requestFormState },
    setAutomaticSearch,
  } = usePotentialReceiverContext();

  const indeterminate = Object.values(requestFormState).some(
    (request) => request.manually_selected,
  );

  const ariaType = useGetCheckboxHeaderAriaType({
    automaticSearch,
    indeterminate,
  });

  return (
    <div style={{ textOverflow: "" }}>
      <CheckboxInputFieldPresenter
        inputProps={{
          "aria-label": ariaType.label,
          "aria-checked": ariaType.checked,
        }}
        indeterminate={indeterminate}
        label=""
        checkboxSize={CHECKBOX_SIZE.LARGE}
        disabled={disabled}
        elementName="automatic-search"
        value={automaticSearch}
        onChange={() => {
          setAutomaticSearch();
          deselectAllRequests();
        }}
      />
    </div>
  );
}
export const shouldDisableSendRequest = (
  disabledActionType?: DisabledSearchActionType,
) => {
  const disabledActionTypes: DisabledSearchActionType[] = [
    DISABLED_SEARCH_ACTION_SEND_REQUESTS_ATTRIBUTED,
    DISABLED_SEARCH_ACTION_SEND_REQUESTS_CANCELLED,
    DISABLED_SEARCH_ACTION_SEND_REQUESTS_ACCEPTED,
    DISABLED_SEARCH_ACTION_SEND_REQUESTS_TRANSPORT_LIMIT,
  ];

  return disabledActionType
    ? disabledActionTypes.includes(disabledActionType)
    : false;
};

export const getCheckboxSettings = ({
  auction,
  requestListState,
  sendRequestsQueryProgress,
}: {
  auction: Auction;
  requestListState?: RequestListState;
  sendRequestsQueryProgress?: QueryProgress;
}): {
  autoSelectHeader: boolean;
  disableHeader: boolean;
  disableRow: boolean;
} => {
  const result = {
    autoSelectHeader: false,
    disableHeader: false,
    disableRow: false,
  };

  const sendRequestAction = findSearchAction(
    auction,
    SEARCH_ACTION_SEND_REQUEST,
  );
  const manualSearchAction = findSearchAction(
    auction,
    SEARCH_ACTION_START_MANUAL_SEARCH,
  );
  const automaticSearchAction = findSearchAction(
    auction,
    SEARCH_ACTION_START_AUTOMATIC_SEARCH,
  );

  if (!sendRequestAction) {
    return { ...result, disableHeader: true, disableRow: true };
  }

  if (
    auction.status === AUCTION_STATUS_NOT_STARTED &&
    (!manualSearchAction || manualSearchAction.context?.disabled)
  ) {
    result.disableHeader = true;
    result.disableRow = true;
  }

  if (automaticSearchAction?.context?.disabled) {
    result.autoSelectHeader = false;
  } else if (automaticSearchAction) {
    result.autoSelectHeader = true;
  }

  if (shouldDisableSendRequest(sendRequestAction.context?.disabled)) {
    result.autoSelectHeader = false;
    result.disableHeader = true;
    result.disableRow = true;
  }

  if (sendRequestsQueryProgress === QUERY_PROGRESS_PENDING) {
    result.disableHeader = true;
    result.disableRow = true;
  }

  if (requestListState?.automaticSearch) {
    result.disableRow = true;
  }

  return result;
};
export function columnCheckbox<Data extends PotentialReceiversTableCandidate>({
  auction,
  requestListState,
  sendRequestsQueryProgress,
}: {
  auction: Auction;
  requestListState?: RequestListState;
  sendRequestsQueryProgress: QueryProgress;
}): GeneralTableColumn<Data, typeof COLUMN_TYPE_SEARCH_TABLE_CHECKBOX> {
  const { disableHeader, disableRow } = getCheckboxSettings({
    auction,
    requestListState,
    sendRequestsQueryProgress,
  });

  return {
    title: <SelectProviderCheckboxHeader disabled={disableHeader} />,
    type: COLUMN_TYPE_SEARCH_TABLE_CHECKBOX,
    getProps: ({ careprovider, id }) => {
      return {
        value: {
          id,
          careprovider_id: careprovider.id,
          manually_selected: false,
          patient_preferred: false,
          patient_preferred_reason: undefined,
        },
        disabled: disableRow,
      };
    },
    width: sizing(2.5),
  };
}

export function handleSearchMergeRowClick<
  Data extends { id: number; recommendation_id?: number },
>({
  auctionId,
  navigationHandlers,
  patientId,
}: {
  auctionId: number;
  navigationHandlers: CareseekerNavigationHandlers;
  patientId: number;
}) {
  return ({
    event,
    rowData,
  }: {
    event: React.MouseEvent<HTMLElement>;
    rowData: Data;
  }) => {
    if (rowData.id <= 0) {
      console.error(
        `[DEV-14068] [handleSearchMergeRowClick] tried to access bad request id ${rowData.id}`,
      );
      return;
    }

    const isRecommendation = !!rowData.recommendation_id;

    if (event.ctrlKey || event.metaKey) {
      const recommendationParam = isRecommendation
        ? `?recommendation=true`
        : "";
      return window.open(
        `/app/clinic/dashboard/patient/${patientId}/auction/${auctionId}/request/${rowData.id}${recommendationParam}`,
        "_blank",
      );
    }

    return navigationHandlers.patient.goToRequest(
      rowData.id,
      patientId,
      auctionId,
      {
        recommendation: isRecommendation,
      },
    );
  };
}

const getSearchTableName = ({
  table,
  translations,
}: {
  table: SearchTable;
  translations: Translations;
}): string => {
  const tableTranslation =
    translations.ontologies.searchPageTableTitle.values?.[table];

  if (!tableTranslation) {
    console.error(
      `search table translation not configured in getSearchTableName for table ${table}`,
    );
    return "";
  }
  return tableTranslation;
};

export function getA11yRowClickProps<
  Request extends { careprovider?: { name: string } },
  Table extends
    | typeof SEARCH_TABLE_ACCEPTED
    | typeof SEARCH_TABLE_CONTACTED
    | typeof SEARCH_TABLE_DECLINED
    | typeof SEARCH_TABLE_REJECTED
    | typeof SEARCH_TABLE_VALIDATED,
>({ table, translations }: { table: Table; translations: Translations }) {
  return ({ data: request }: { data: Request }) => {
    return {
      role: `link`,
      "aria-label":
        translations.search.searchPageTables.accessibility.clickFunctionality({
          providerName: request?.careprovider?.name || "",
          tableName: getSearchTableName({ table, translations }),
        }),
    };
  };
}

export function getA11yPotentialTableRowClickProps({
  isSelected,
  translations,
}: {
  isSelected: (id: number) => boolean;
  translations: Translations;
}) {
  return ({ data: request }: { data: PotentialReceiversTableCandidate }) => {
    return {
      role: `menuitemcheckbox`,
      "aria-label": isSelected(request?.id)
        ? translations.search.searchPageTables.accessibility.deselectProvider({
            providerName: request?.careprovider?.name || "unbekannt",
          })
        : translations.search.searchPageTables.accessibility.selectProvider({
            providerName: request?.careprovider?.name || "unbekannt",
          }),
    };
  };
}

export function columnContactAnyway<
  Data extends FilteredTableFilteredCandidate,
>({
  auction,
  refresh,
}: {
  auction: Auction;
  refresh: () => void;
}): GeneralTableColumn<Data, typeof COLUMN_TYPE_SEARCH_TABLE_CONTACT_ANYWAY> {
  return {
    type: COLUMN_TYPE_SEARCH_TABLE_CONTACT_ANYWAY,
    getProps: ({ careprovider, recommendation_id, send_fax }) => {
      return {
        auction,
        recommendationId: recommendation_id,
        sendFax: send_fax,
        careprovider,
        refresh,
      };
    },
    width: "5%",
  };
}

export const shouldDisableRowClick = ({
  auctionId,
  requestId,
  table,
}: {
  auctionId: number;
  requestId: number | null | undefined;
  table: SearchTable;
}) => {
  if (!requestId) {
    console.error(
      `[DEV-14068] [shouldDisableRowClick] no request id (${requestId}) received for row in search merge table ${table} for auction ${auctionId}`,
    );
    return true;
  }
  if (requestId > 0) {
    return false;
  }

  const isUnknownProviderValidation =
    table === SEARCH_TABLE_VALIDATED && requestId === -1;

  if (!isUnknownProviderValidation) {
    console.error(
      `[DEV-14068] [shouldDisableRowClick] bad request id (${requestId}) received for row in search merge table ${table} for auction ${auctionId}`,
    );
  }
  return true;
};
